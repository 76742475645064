import React from "react";
import Banner1 from "../../../Assets/Images/Trend/Banner1.webp";
import Banner2 from "../../../Assets/Images/Trend/Banner2.webp";
import Pattern1 from "../../../Assets/Images/Trend/Pattern1.webp";
import Pattern2 from "../../../Assets/Images/Trend/Pattern2.webp";
import Pattern3 from "../../../Assets/Images/Trend/Pattern3.webp";
import Pattern4 from "../../../Assets/Images/Trend/Pattern4.webp";
import TrendStars from "../../../Assets/Images/TrendStars.png";

const AboutUs = () => {
  return (
    <section className="bg-transparent p-4 md:p-0">
      {/* Content */}
      <div className="min-h-0 md:min-h-screen flex flex-col md:flex-row items-center gap-8 px-4 md:px-8 lg:px-12 2xl:pl-auto">
        {/* Left Text Content */}
        <div className="flex-1 h-auto text-center md:text-center xl:text-start -translate-y-11">
          <div className="relative text-right mr-9 md:mr-40 lg:mr-6 xl:mr-[13rem] 2xl:mr-[25rem] mt-6 md:mt-0">
            <p className="text-[#B07641] inline text-sm md:text-base lg:text-lg 2xl:text-2xl">
              Discover Trend Design
            </p>
            <img
              src={TrendStars}
              alt="Trend Stars"
              className="absolute top-[-1rem] md:top-[-1.5rem] right-[-2rem] md:right-[-3rem] w-[30px] md:w-[40px] object-contain"
            />
          </div>
          <h3 className="text-[28px] md:text-[32px] lg:text-[40px] font-normal leading-relaxed mb-4 2xl:mb-12">
            Trend design and class{" "}
            <br />
            <img
              src={Pattern4}
              alt="Pattern 1"
              className="w-[60px] md:w-[84px] 2xl:w-[130px] h-[24px] md:h-[34px] 2xl:h-[53px] object-cover inline mr-2"
            />
            with{" "}
            <span className="text-[#B07641]">
              Group Digital <br className="block 2xl:hidden"/> Printing
            </span>
            .
          </h3>
          <p className="text-sm md:text-base 2xl:text-[25px] text-[#777777] leading-6 md:leading-7 mb-6">
            This section features the best designs that keep you at the forefront of modern fashion, offering trendy pieces that help define your look with the latest colors and shapes, all crafted to suit your individual style .
          </p>
          <br className="hidden 2xl:block" />
          {/* Patterns */}
          <div className="flex flex-wrap items-center justify-center lg:justify-start gap-4 mb-6">
            <img
              src={Pattern1}
              alt="Pattern 1"
              className="w-[80px] md:w-[108px] h-[32px] md:h-[43px] object-cover"
            />
            <img
              src={Pattern2}
              alt="Pattern 2"
              className="w-[80px] md:w-[108px] h-[32px] md:h-[43px] object-cover"
            />
            <img
              src={Pattern3}
              alt="Pattern 3"
              className="w-[80px] md:w-[108px] h-[32px] md:h-[43px] object-cover"
            />
          </div>
          <br className="hidden 2xl:block" />
          {/* Shop Now Button */}
          <div className="pt-[4px] w-[150px] 2xl:w-[175px] border border-[#B07641] rounded-br-[30px] rounded-tl-[30px] mx-auto lg:mx-0">
            <button className="text-base 2xl:text-xl bg-[#B07641] text-white py-2 md:py-3 px-8 rounded-br-[30px] rounded-tl-[30px] shadow-lg hover:bg-[#cd8542]">
              Shop Now
            </button>
          </div>
        </div>

        {/* Right Image Section */}
        <div className="flex-1 relative translate-x-[150px] xl:translate-x-0 translate-y-[20px] 2xl:translate-y-[0px] hidden lg:block">
          <span className="absolute top-[-64px] md:top-[-164px] right-[50px] md:right-[100px] text-lg md:text-3xl rotate-90 z-40 text-shadow">
            Trend Design
          </span>

          {/* Background Shape */}
          <div className="absolute w-[200px] md:w-[375px] h-[360px] md:h-[672px] right-[45px] md:right-[91px] top-[-200px] md:top-[-518px] z-20">
            <img
              src={Banner1}
              alt="Background"
              className="object-cover max-w-full h-auto"
            />
          </div>
          {/* Teardrop Shape */}
          <div className="absolute w-[180px] md:w-[270px] 2xl:w-[300px] h-[360px] md:h-[592px] right-[8rem] md:right-[19.5rem] top-[-200px] md:top-[-330px] clip-teardrop z-30">
            <img
              src={Banner2}
              alt="Teardrop"
              className="object-cover max-w-full h-auto"
            />
          </div>
          <div className="absolute w-[180px] lg:w-[310px] 2xl:w-[307px] h-[360px] md:h-[592px] 2xl:h-[649px] right-[9rem] md:right-[17.9rem] lg:right-[294px] xl:right-[17.9rem] 2xl:right-[19.9rem] top-[-210px] md:top-[-357px] xl:top-[-365px] clip-teardrop z-10">
            <svg
              viewBox="0 0 342 645"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="lg:w-[310px] xl:w-[299px] 2xl:w-[330px] lg:h-[588px] xl:h-[616px] 2xl:h-[672px]"
            >
              <rect
                x="0.5"
                y="0.5"
                width="341"
                height="644"
                rx="170.5"
                stroke="#B07641"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
