import React from 'react';
import HeroSection from '../Components/Opal/Printer/HeroSection';
import HeroSection2 from '../Components/Opal/Printer/HeroSection2';
import PrintingProcessSection from '../Components/Opal/Printer/PrintingProcessSection';
import SublimationPrintingSection from '../Components/Opal/Printer/SublimationPrinting';
import SublimationFeaturesSection from '../Components/Opal/Printer/SublimationFeaturesSection';
import SublimationProcessSection from '../Components/Opal/Printer/SublimationProcess';
import SublimationExampleSection from '../Components/Opal/Printer/SublimationExamples';
import AdvantageDTF from '../Components/Opal/Printer/AdvantageDTF';
import DTFPrintingProcess from '../Components/Opal/Printer/DTFPrintingProcess';
import DTFPrintingExample from '../Components/Opal/Printer/DTFPrinting';
import DTFPrintingTechniq from '../Components/Opal/Printer/DTFPrintingTechniq';


const PrinterPage = () => {
    return (
        <div className="overflow-hidden">
            <HeroSection />
            <HeroSection2 />
            <div className="bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]/60">
                <PrintingProcessSection />
                <SublimationPrintingSection />
                <SublimationFeaturesSection />
                <SublimationProcessSection />
                <SublimationExampleSection />
                <DTFPrintingTechniq/>
                <AdvantageDTF />
                <DTFPrintingProcess />
                <DTFPrintingExample />
                <section className="flex flex-col items-center justify-center text-center p-10 min-h-[300px] bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]/60">
                    <p className="text-xl font-semibold text-gray-800">
                        Let us add a creative touch to your fabric.<br />
                        Reach out now !!!
                    </p>
                    <p className="text-md text-[#767676] mt-2">
                        Let us help bring your vision to life!
                    </p>
                    <button className="mt-6 px-6 py-3 bg-transparent text-black text-md rounded-lg shadow-md border border-[#750E21] transition">
                        Contact Us
                    </button>
                </section>
            </div>
        </div>
    );
};

export default PrinterPage;
