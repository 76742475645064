import { CheckCircle } from "lucide-react";
import PrinterMachine from "../../../Assets/Images/Printer/PrinterMachine.webp";
import { SingleStar } from "../../../Assets/Svg/PrinterSvg";

const features = [
  "Provides high-quality, vivid color reproduction",
  "Can be printed on a variety of materials, including polyester.",
  "Delivers long-lasting prints with exceptional resistance to fading.",
  "Ideal for on-demand printing and small batch orders.",
  "The design becomes part of the fabric, ensuring it won’t peel or fade."
];

const SublimationFeatures = () => {
  return (
    <section className="relative h-full md:h-[38rem] 3xl:h-[43rem] py-12 px-6 md:px-12 lg:px-24">
      {/* Single Star at the Top Center */}
      <div className="absolute top-0 xl:top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <SingleStar className="w-12 h-12" />
      </div>

      <div className="flex flex-col lg:flex-row items-center h-full gap-10">
        
        {/* Left Image Section */}
        <div className="lg:w-1/2 xl:h-[25rem] 2xl:h-[32rem] 3xl:h-[33rem] mt-auto relative hidden xl:block">
          <div className="rounded-lg h-full">
            <img 
              src={PrinterMachine} 
              loading="lazy"
              alt="Sublimation Printing Machine" 
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Right Features Section */}
        <div className="w-full lg:w-[70%] xl:w-[43%] mx-auto">
          <h2 className="text-2xl md:text-3xl text-center text-gray-900">
            Features of <span className="text-[#D72323]">Sublimation</span><br/> Printing ...
          </h2>

          {/* Features List */}
          <ul className="mt-6 space-y-6">
            {features.map((feature, index) => (
              <li 
                key={index} 
                className={`relative flex items-center gap-3 border-2 border-[#750E21] rounded-lg px-4 py-3 shadow-md ${
                  index % 2 === 0 ? "flex-row-reverse" : "flex-row translate-x-4"
                }`}
              >
                {/* CheckCircle Icon */}
                <div 
                  className={`absolute top-2 ${
                    index % 2 === 0 ? "-right-3" : "-left-3"
                  } bg-[#750E21] p-1 rounded-full`}
                >
                  <CheckCircle className="text-white w-6 h-6" />
                </div>
                {/* Feature Text */}
                <span className="text-gray-700 mx-auto text-[10px] md:text-[19px] lg:text-[16px] xl:text-[15px] 2xl:text-[20px]">{feature}</span>
              </li>
            ))}
          </ul>
        </div>

      </div>
    </section>
  );
};

export default SublimationFeatures;
