import React from "react";
import { BgHeroSection1 } from "../../../Assets/Svg/PrinterSvg";
import Design1 from "../../../Assets/Images/Printer/Design1.webp"
import Design2 from "../../../Assets/Images/Printer/Design2.webp"
import Design3 from "../../../Assets/Images/Printer/Design3.webp"

const fabricDesigns = [
    { id: 1, src: Design1, alt: "Fabric Design 1" },
    { id: 2, src: Design2, alt: "Fabric Design 2" },
    { id: 3, src: Design3, alt: "Fabric Design 3" },
];

const FabricSection = () => {
    return (
        <div className="py-16 px-6 lg:px-12 text-center relative">

            <div className="absolute top-0 left-0 w-full">
                <BgHeroSection1 />
            </div>
            {/* Top Text */}
            <p className="relative max-w-5xl 2xl:max-w-7xl mx-auto text-[16px] lg:text-[18px] 2xl:text-[21px] text-[#8A888B] z-20">
                We have extensive experience in using the latest digital printing
                technologies on fabrics, which enables us to deliver precise designs
                with exceptional craftsmanship. We believe that every piece of fabric
                tells a unique story, and therefore, we focus on offering personalized
                services based on our clients' specific requirements.
            </p>
            {/* Fabric Images */}
            <div className="mt-8 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                {fabricDesigns.map((fabric) => (
                    <div key={fabric.id} className="overflow-hidden rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 z-30">
                        <img
                            src={fabric.src}
                            alt={fabric.alt}
                            className="w-full md:h-40 lg:h-64 object-cover"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FabricSection;
