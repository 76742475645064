import DTFPrintingProcess1 from "../../../Assets/Images/Printer/DTFPrintingProcess1.webp";
import DTFPrintingProcess2 from "../../../Assets/Images/Printer/DTFPrintingProcess2.webp";
import DTFPrintingProcess3 from "../../../Assets/Images/Printer/DTFPrintingProcess3.webp";
import DTFPrintingProcess4 from "../../../Assets/Images/Printer/DTFPrintingProcess4.webp";

export default function DTFPrintingProcess() {
  return (
    <section className="max-w-7xl mx-auto px-4 py-12 min-h-screen">
      {/* Header */}
      <div className="text-center mb-8">
        <h2 className="text-3xl md:text-4xl">Get to know how the</h2>
        <h3 className="text-2xl md:text-3xl text-[#D72323]">
          DTF printing process works, step by step
        </h3>
        <p className="text-lg text-gray-500">
          We help you to provide the highest quality
        </p>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Section (Cards) */}
        <div className="grid grid-cols-1 md:grid-cols-2 h-full md:h-[350px] my-auto gap-4">
          <div className="bg-[#750E21] text-white p-6 rounded-lg shadow">
            <h1 className="text-2xl">1</h1>
            <h2 className="mb-4 mt-2">Design Selection</h2>
            <p>Choose the appropriate design for the fabric type.</p>
          </div>
          <div className="bg-[#F8F7F6] text-[#750E21] p-6 rounded-lg shadow">
            <h1 className="text-2xl">2</h1>
            <h2 className="mb-4 mt-2">DTF Printing</h2>
            <p>Print the design onto the film using DTF technology.</p>
          </div>
          <div className="bg-[#F8F7F6] text-[#750E21] p-6 rounded-lg shadow">
            <h1 className="text-2xl">3</h1>
            <h2 className="mb-4 mt-2">Heat Transfer</h2>
            <p>
              Coat the design with adhesive powder, then use heat to transfer it to the fabric.
            </p>
          </div>
          <div className="bg-[#750E21] text-white p-6 rounded-lg shadow">
            <h1 className="text-2xl">4</h1>
            <h2 className="mb-4 mt-2">Delivery</h2>
            <p>Deliver the printed garment.</p>
          </div>
        </div>

        {/* Right Section (Images) */}
        <div className="hidden lg:grid grid-cols-2 grid-rows-3 gap-2">
          <img
            src={DTFPrintingProcess1}
            alt="Top Left"
            className="row-span-2 w-full h-[100px] md:h-full object-cover rounded-lg"
          />
          <img
            src={DTFPrintingProcess2}
            alt="Top Right"
            className="w-full h-[200px] md:h-[175px] object-cover rounded-lg"
          />
          <img
            src={DTFPrintingProcess3}
            alt="Middle Right"
            className="row-span-2 w-full h-[100px] md:h-full object-cover rounded-lg"
          />
          <img
            src={DTFPrintingProcess4}
            alt="Bottom Left"
            className="w-full h-[200px] md:h-[175px] object-cover rounded-lg"
          />
        </div>
      </div>
    </section>
  );
}
