import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import SublimationEx1 from "../../../Assets/Images/Printer/SublimationEx1.webp";
import SublimationEx2 from "../../../Assets/Images/Printer/SublimationEx2.webp";
import SublimationEx3 from "../../../Assets/Images/Printer/SublimationEx3.webp";
import SublimationEx4 from "../../../Assets/Images/Printer/SublimationEx4.webp";
import SublimationEx5 from "../../../Assets/Images/Printer/SublimationEx5.webp";
import SublimationEx6 from "../../../Assets/Images/Printer/SublimationEx6.webp";
import SublimationEx7 from "../../../Assets/Images/Printer/SublimationEx7.webp";
import SublimationEx8 from "../../../Assets/Images/Printer/SublimationEx8.webp";

const SublimationExamples = () => {
  const images = [
    { src: SublimationEx1, rowSpan: 3, colSpan: 1 },
    { src: SublimationEx3, rowSpan: 2, colSpan: 1 },
    { src: SublimationEx7, rowSpan: 1, colSpan: 0.5 },
    { src: SublimationEx4, rowSpan: 1, colSpan: 0.5 },
    { src: SublimationEx5, rowSpan: 1, colSpan: 1 },
    { src: SublimationEx2, rowSpan: 0.5, colSpan: 1 },
    { src: SublimationEx6, rowSpan: 1, colSpan: 2 },
    { src: SublimationEx8, rowSpan: 1, colSpan: 1 },
  ];

  const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    const handleResize = () => setIsTabletOrMobile(mediaQuery.matches);

    handleResize(); // Initial check
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <div className="py-16 min-h-[100px]">
      {/* Title Section */}
      <div className="text-center mb-10">
        <h2 className="text-3xl lg:text-[34px]">
          Examples of <span className="text-red-500">Sublimation</span> Printing
        </h2>
        <p className="text-gray-700 mt-2 max-w-xl mx-auto">
          Sublimation printing includes apparel printing, custom products, and
          printing on both light and dark fabrics with detailed, vibrant designs.
        </p>
      </div>

      {/* Conditional Rendering */}
      {isTabletOrMobile ? (
        <div className="relative w-full max-w-lg mx-auto">
          {/* Swiper Slider */}
          <Swiper
            spaceBetween={10}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            modules={[Pagination]}
            breakpoints={{
              0: { slidesPerView: 1 }, // Mobile: 1 slide per page
              768: { slidesPerView: 3 }, // Tablet: 3 slides per page
            }}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index}>
                <img
                  src={img.src}
                  alt={`Sublimation example ${index + 1}`}
                  className="w-full h-64 object-cover rounded-lg"
                  loading="lazy"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Pagination Below Swiper */}
          <div className="swiper-pagination mt-4 !relative"></div>
        </div>
      ) : (
        // Masonry Grid for desktop
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-2 auto-rows-[minmax(100px,_auto)]">
          {images.map((img, index) => (
            <div
              key={index}
              className={`col-span-${img.colSpan} row-span-${img.rowSpan}`}
            >
              <img
                src={img.src}
                alt={`Sublimation example ${index + 1}`}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SublimationExamples;
