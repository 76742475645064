import React from "react";
import CenterImage from "../../../Assets/Images/changing/centerImage.webp";
import TopLeftImage from "../../../Assets/Images/changing/topLeftImage.webp";
import BottomRightImage from "../../../Assets/Images/changing/BottomRightImage.webp";

const OurCollection = () => {
    return (
        <section className="w-full px-6 py-8 lg:py-10 xl:py-12 bg-white">
            <div className="text-center mb-12 lg:mb-20">
                <h2 className="text-3xl xl:text-4xl text-[#B07641]">
                    Our Collection
                </h2>
            </div>
            <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 lg:gap-12 xl:gap-8 2xl:gap-20">
                {/* Left Column */}
                <div className="max-w-full lg:max-w-lg 2xl:max-w-2xl my-auto space-y-6 text-center lg:text-justify">
                    <h2 className="text-[22px] xl:text-[27px] 2xl:text-[30px] font-bold text-gray-700 leading-relaxed">
                        Updated daily, our vast collection of designs is hand-selected.
                    </h2>
                    <br />
                    <p className="text-[17px] xl:text-xl 2xl:text-[26px] text-gray-600 leading-relaxed">
                        Our collection is designed with the modern creator in mind. Offering a wide array of fabrics, from timeless classics to contemporary trends, we provide you with the materials needed to produce exceptional clothing that expresses your individuality. With our carefully selected.
                    </p>
                    <br />
                    <p className="text-[17px] xl:text-xl 2xl:text-2xl text-gray-600 leading-relaxed">
                        Our collection blends luxury with creativity in every fabric.
                    </p>
                </div>

                {/* Right Column: Image Grid */}
                <div className="relative w-full 2xl:w-[665px] h-[400px] md:h-[500px] hidden lg:flex items-center justify-center">
                    {/* Center Image */}
                    <img
                        src={CenterImage}
                        alt="Center Design"
                        className="absolute top-1/2 left-1/2 lg:w-[198px] 1340:w-[244px] xl:w-[244px] 2xl:w-[280px] h-auto object-cover transform -translate-x-1/2 -translate-y-1/2 z-20"
                    />

                    <svg viewBox="0 0 327 328" fill="none" xmlns="http://www.w3.org/2000/svg" className="lg:w-[265px] lg:h-[263px] 1340:w-[327px] xl:w-[327px] 1340:h-[328px] xl:h-[328px] 2xl:w-[365px] 2xl:h-[370px] absolute top-1/2 left-1/2 h-auto object-cover transform -translate-x-1/2 -translate-y-1/2 z-10">
                        <path d="M230.26 230.381C189.369 271.46 144.848 301.15 105.941 315.955C66.9864 330.778 33.8623 330.616 15.5242 312.362C-2.81381 294.107 -3.12827 260.984 11.5161 221.962C26.1426 182.988 55.6272 138.331 96.5182 97.2519C137.409 56.1729 181.93 26.4837 220.837 11.6785C259.792 -3.14472 292.916 -2.98226 311.254 15.2718C329.592 33.5259 329.906 66.649 315.262 105.671C300.635 144.646 271.151 189.302 230.26 230.381Z" stroke="#B07641" />
                    </svg>

                    {/* Top-Left Image */}
                    <img
                        src={TopLeftImage}
                        alt="Top Left Design"
                        className="absolute top-0 left-0 lg:w-[139px] 1340:w-[157px] xl:w-[157px] 2xl:w-[175px] h-auto object-cover transform -translate-x-4 -translate-y-4 z-10"
                    />

                    <svg viewBox="0 0 165 216" fill="none" xmlns="http://www.w3.org/2000/svg" className="lg:w-[145px] lg:h-[192px] 1340:w-[165px] xl:w-[165px] 2xl:w-[185px] 1340:h-[213px] xl:h-[213px] 2xl:h-[240px] absolute top-0 left-0 h-auto object-cover transform -translate-x-4 -translate-y-4 z-0">
                        <path d="M0.5 0.5H65C119.952 0.5 164.5 45.0477 164.5 100V133.5C164.5 178.787 127.787 215.5 82.5 215.5C37.2127 215.5 0.5 178.787 0.5 133.5V0.5Z" stroke="#B07641" />
                    </svg>

                    {/* Bottom-Right Image */}
                    <img
                        src={BottomRightImage}
                        alt="Bottom Right Design"
                        className="absolute bottom-0 right-0 lg:w-[139px] 1340:w-[157px] xl:w-[157px] 2xl:w-[175px] h-auto object-cover transform translate-x-4 translate-y-4 z-10"
                    />

                    <svg viewBox="0 0 163 220" fill="none" xmlns="http://www.w3.org/2000/svg" className="lg:w-[145px] lg:h-[192px] 1340:w-[165px] xl:w-[165px] 2xl:w-[185px] 1340:h-[213px] xl:h-[213px] 2xl:h-[240px] absolute bottom-0 right-0 h-auto object-cover transform translate-x-4 translate-y-4 z-0">
                        <path d="M0.5 81.5C0.5 36.7649 36.7649 0.5 81.5 0.5C126.235 0.5 162.5 36.7649 162.5 81.5V219.5H100C45.0477 219.5 0.5 174.952 0.5 120V81.5Z" stroke="#B07641" />
                    </svg>
                </div>
            </div>
        </section>
    );
};

export default OurCollection;
