import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dividor from "../dividor";

import image1 from "../../../Assets/Images/Home/image1.webp"
import image2 from "../../../Assets/Images/Home/image2.webp"
import image3 from "../../../Assets/Images/Home/image3.webp"
import image4 from "../../../Assets/Images/Home/image4.webp"

const NewCollection = () => {
  const images = [image1, image2, image3, image4];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Default number of slides for large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2, // Show 2 slides
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1, // Show 1 slide
        },
      },
    ],
  };

  return (
    <section className="relative min-h-screen p-0 pt-8 xl:p-6 bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]">

      <div className="absolute inset-0 flex justify-center items-center">
        <h1 className="text-[100px] xl:text-[140px] 2xl:text-[180px] text-[#B07641] text-center opacity-10 z-[30] rotate-[-31.7deg] select-none hidden lg:block">
          NEW COLLECTION
        </h1>
      </div>

      {/* Section Header */}
      <div className="text-center mb-12 relative z-10">
        <h1 className="text-[28px] md:text-[36px] font-bold text-[#000000]">
          New Collection
        </h1>
        <Dividor width="w-[100px]" />
        <p className="text-[16px] md:text-[18px] text-gray-700 mt-2">
          Discover our latest designs, blending art with craftsmanship to create timeless beauty.
        </p>
      </div>

      {/* Grid Section */}
      <div className="hidden lg:grid lg:w-[100%] xl:w-[87%] 2xl:w-[70%] mx-auto grid-cols-3 gap-6 relative z-10">
        {/* Row 1 */}
        <div className="col-span-2 w-[595px] xl:w-[700px] h-[350px]">
          <img
            src={image4}
            alt="Abstract Textured Floral Background"
            className="w-full h-[276px] object-cover rounded-tr-[50px] rounded-bl-[50px]"
          />
          <div className="flex justify-between items-center p-4 rounded-b-lg">
            {/* Left Column */}
            <div>
              <h4 className="text-[18px] font-semibold text-[#B07641]">
                Abstract Textured Floral Background
              </h4>
              <p className="text-gray-600 text-[14px]">PSD, TIFF, PNG</p>
            </div>
            {/* Right Column - Button */}
            <button className="px-4 py-1 border border-[#B07641] text-black text-sm rounded-xl">
              Show Details
            </button>
          </div>
        </div>

        <div className="row-span-2 ml-auto w-[357px] h-[595px] flex flex-col">
          {/* Image */}
          <img
            src={image3}
            alt="Abstract Textured Floral Background"
            className="w-full h-[484px] object-cover rounded-tl-[50px] rounded-br-[50px]"
          />

          {/* Details Section */}
          <div className="flex flex-col p-4 rounded-b-lg flex-grow">
            {/* Left Column */}
            <div>
              <h4 className="text-[18px] font-semibold text-[#B07641]">
                Abstract Textured Floral Background
              </h4>
              <p className="text-gray-600 text-[14px]">PSD, TIFF, PNG</p>
            </div>

            {/* Button Positioned at Bottom */}
            <button className="px-4 py-1 border border-[#B07641] text-black text-sm rounded-xl ml-auto">
              Show Details
            </button>
          </div>
        </div>

        <div className="row-span-2 flex flex-col justify-between w-[357px] h-[595px]">
          {/* Header 4 */}
          <img
            src={image2}
            alt="Abstract Textured Floral Background"
            className="w-full h-[484px] object-cover rounded-tl-[50px] rounded-br-[50px]"
          />
          <div className="flex flex-col p-4 rounded-b-lg flex-grow">
            {/* Left Column */}
            <div>
              <h4 className="text-[18px] font-semibold text-[#B07641]">
                Abstract Textured Floral Background
              </h4>
              <p className="text-gray-600 text-[14px]">PSD, TIFF, PNG</p>
            </div>

            {/* Button Positioned at Bottom */}
            <button className="px-4 py-1 border border-[#B07641] text-black text-sm rounded-xl ml-auto">
              Show Details
            </button>
          </div>
        </div>

        {/* Empty Cell */}
        <div className="h-[73px]"></div>

        {/* Row 3 */}
        <div className="col-span-2 ml-auto w-[595px] xl:w-[700px] h-[276px]">
          <img
            src={image1}
            alt="Abstract Textured Floral Background"
            className="w-full h-full object-cover rounded-tr-[50px] rounded-bl-[50px]"
          />
          <div className="flex justify-between items-center p-4 rounded-b-lg">
            {/* Left Column */}
            <div>
              <h4 className="text-[18px] font-semibold text-[#B07641]">
                Abstract Textured Floral Background
              </h4>
              <p className="text-gray-600 text-[14px]">PSD, TIFF, PNG</p>
            </div>
            {/* Right Column - Button */}
            <button className="px-4 py-1 border border-[#B07641] text-black text-sm rounded-xl">
              Show Details
            </button>
          </div>
        </div>
      </div>
      <div className="bg-transparent flex lg:hidden items-center justify-center px-4 py-8">
            <div className="w-full max-w-6xl mx-auto">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="px-2">
                            <img
                                src={image}
                                alt={`Slide ${index + 1}`}
                                className="w-full h-[225px] object-cover rounded-md"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    </section>
  );
};

export default NewCollection;
