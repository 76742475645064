import React from "react";

export const PriceBg = ({ startColor, endColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355 236" fill="none" className="w-full h-auto bg-transparent">
    <path 
      d="M0 69.9544C0 31.2945 31.3401 0 70 0H355V90.1772V138.011C355 182.43 314.151 215.859 270.292 208.827C165.284 191.993 0 173.534 0 224.15C0 274.533 0 153.068 0 69.9544Z" 
      fill={`url(#paint0_linear_${startColor.replace('#', '')})`}
    />
    <defs>
      <linearGradient 
        id={`paint0_linear_${startColor.replace('#', '')}`} 
        x1="0" 
        y1="118" 
        x2="355" 
        y2="118" 
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={startColor} />
        <stop offset="1" stopColor={endColor} />
      </linearGradient>
    </defs>
  </svg>
);