import React from "react";
import Logo from "../Logo";

const DesktopFooter = () => {
    return (
        <div className="hidden lg:grid gap-10 row-gap-8 mb-8 grid-cols-[2fr_1fr_1fr_1fr]">
            {/* Logo and Description */}
            <div className="space-y-10 text-sm">
                <Logo  width={'w-[185px] md:w-[185px] xl:w-[200px] 2xl:w-[215px]'} height={'h-[80px] 2xl:h-[100px]'} />
                <p className="text-[16px] mt-[50px] tracking-wide text-gray-900">
                    Luxury fabrics and innovative designs for your elegance.
                </p>
            </div>

            {/* Explore Section */}
            <div className="space-y-2 text-sm w-full lg:w-[216px]">
                <p className="text-base font-bold tracking-wide text-gray-900">Explore</p>
                <div className="flex">
                    <a
                        href="tel:850-123-5021"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        All Design
                    </a>
                </div>
                <div className="flex">
                    <a
                        href="mailto:info@lorem.mail"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        Print Trends
                    </a>
                </div>
                <div className="flex">
                    <a
                        href="https://www.google.com/maps"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        Catwalk Analysis
                    </a>
                </div>
            </div>

            {/* Help Section */}
            <div className="space-y-2 text-sm w-full lg:w-[216px]">
                <p className="text-base tracking-wide font-bold text-gray-900">Help</p>
                <div className="flex">
                    <a
                        href="tel:850-123-5021"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        Support
                    </a>
                </div>
                <div className="flex">
                    <a
                        href="mailto:info@lorem.mail"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        Licensing
                    </a>
                </div>
            </div>

            {/* About Section */}
            <div className="space-y-2 text-sm">
                <p className="text-base tracking-wide font-bold text-gray-900">About</p>
                <div className="flex">
                    <a
                        href="tel:850-123-5021"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        About Us
                    </a>
                </div>
                <div className="flex">
                    <a
                        href="mailto:info@lorem.mail"
                        className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 border-black border-b-2"
                    >
                        Sell your Designs
                    </a>
                </div>
            </div>

            {/* Address, Phone, and Email in one line */}
            <div className="flex items-center space-x-4 text-base tracking-wide text-gray-900">
                <i className="text-[20px] fas fa-map-marker-alt text-black" />
                <span>5FV5+94, Al Obour, Al-Qalyubia Governorate, Egypt</span>
            </div>
            <div className="flex items-center space-x-4 text-base tracking-wide text-gray-900">
                <i className="text-[20px] fas fa-phone-alt rotate-[100deg] text-black" />
                <span>(+20) 10 12816000⁩</span>
            </div>
            <div className="flex items-center space-x-4 text-base tracking-wide text-gray-900">
                <i className="text-[20px] fas fa-envelope text-black mt-1" />
                <span>info@kk-textiles.com</span>
            </div>
        </div>
    );
};

export default DesktopFooter;
