import React from "react";
import GooglePlayIcon from "../../../Assets/Icons/GooglePlay.webp";
import AppStoreIcon from "../../../Assets/Icons/AppleStore.webp";

const DownloadAppSection = () => {
    return (
        <section className="w-full px-6 py-12 bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]">
            <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 2xl:gap-14 items-center">
                {/* Left Column: Paragraph */}
                <div className="space-y-6 text-center lg:text-left">
                    <h2 className="lg:w-full xl:w-[25rem] 2xl:w-[28rem] text-[22px] lg:text-2xl 2xl:text-3xl font-bold text-gray-700">
                        Join the Group Digital Printing Application
                    </h2>
                    <p className="text-[17px] lg:text-lg xl:text-xl 2xl:text-[23px] text-center lg:text-justify text-gray-600 leading-relaxed">
                    Get inspired and shop with ease download our app to enjoy exclusive deals and seamless shopping experience directly from your phone.
                    </p>
                </div>

                {/* Right Column: Download Box */}
                <div className="w-full md:w-[55%] lg:w-[91%] xl:w-3/4 2xl:w-full bg-[#B07641] py-8 px-4 md:px-8 rounded-lg text-center flex flex-col items-center justify-center space-y-6 mx-auto">
                    <h2 className="text-xl 2xl:text-3xl text-white">Download The App!</h2>
                    <div className="flex space-x-4">
                        {/* Google Play Button */}
                        <button className="flex items-center justify-center w-36 2sm:w-40 md:w-40 lg:w-[10rem] 2xl:w-[13rem] lg:py-0 xl:py-2 bg-black text-white text-sm 2xl:text-lg rounded-md shadow-md">
                            <img
                                src={GooglePlayIcon}
                                alt="Google Play"
                                className="w-8 2xl:w-10 h-8 2xl:h-10 mr-2"
                            />
                            <div className="text-left">
                                Get it on <br /> Google Play
                            </div>
                        </button>

                        {/* App Store Button */}
                        <button className="flex items-center justify-center w-36 2sm:w-40 md:w-40 lg:w-[10rem] 2xl:w-[13rem] xl:w-48 px-4 py-2 bg-black text-white text-sm 2xl:text-lg rounded-md shadow-md">
                            <img
                                src={AppStoreIcon}
                                alt="App Store"
                                className="w-8 2xl:w-10 h-8 2xl:h-10 mr-2"
                            />
                            <div className="text-left">
                                Get it on <br /> App Store
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DownloadAppSection;
