import DTFPrintTech from "../../../Assets/Images/Printer/DTFPrintTech.webp";

const DTFPrintingTechniqSection = () => {
    return (
        <section className="flex flex-col items-center text-center px-6 py-12 relative">
            {/* Title */}
            <h2 className="text-3xl 3xl:text-[2.25rem] text-gray-900 text-shadow-md">
                Explore Our <span className="text-[#9D2235]">DTF</span> Printing<br />
                Techniques !
                <span className="block border-b-4 border-[#9D2235] w-16 mx-auto mt-3"></span>
            </h2>

            {/* Content Wrapper */}
            <div className="flex flex-col lg:flex-row items-center justify-center gap-10 mt-10 w-full max-w-5xl">
                {/* Left Card */}
                <div className="relative mx-auto hidden lg:flex flex-col items-center px-[14px] py-[14px] bg-transparent rounded-[9.5rem] border border-[#9D2235] shadow-lg w-[270px] h-[529px]">
                    <svg viewBox="0 0 477 411" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute z-0 -top-[3rem] w-[445px] h-[425px] mx-auto">
                        <path d="M295.175 279.43C245.217 315.355 194.39 340.057 152.614 350.962C131.724 356.416 113.124 358.413 98.0381 356.657C82.9529 354.902 71.437 349.403 64.6149 339.916C57.7927 330.428 56.245 317.761 59.3823 302.902C62.5197 288.042 70.3334 271.045 82.1524 252.977C105.788 216.845 145.383 176.524 195.341 140.599C245.3 104.674 296.126 79.9726 337.902 69.0667C358.792 63.6133 377.393 61.6162 392.478 63.3718C407.563 65.1273 419.079 70.6265 425.901 80.1136C432.724 89.6006 434.271 102.268 431.134 117.127C427.997 131.987 420.183 148.984 408.364 167.052C384.728 203.184 345.134 243.505 295.175 279.43Z" stroke="#750E21" />
                        <path d="M282.927 271.866C232.969 307.791 182.045 332.355 140.112 343.041C119.143 348.384 100.449 350.25 85.2589 348.35C70.0698 346.449 58.4357 340.791 51.4906 331.133C44.5456 321.475 42.885 308.645 45.9181 293.641C48.9513 278.636 56.671 261.508 68.4105 243.331C91.8877 206.98 131.383 166.524 181.341 130.599C231.299 94.6741 282.223 70.1098 324.156 59.4241C345.124 54.0809 363.819 52.2145 379.009 54.115C394.198 56.0154 405.832 61.6735 412.777 71.3314C419.722 80.9893 421.383 93.8193 418.35 108.823C415.316 123.828 407.597 140.956 395.857 159.133C372.38 195.485 332.884 235.941 282.927 271.866Z" stroke="#750E21" />
                        <rect x="337" y="40.6621" width="15" height="36" transform="rotate(-30.72 337 40.6621)" fill="#F6F0EC" />
                        <rect x="34" y="294.303" width="28" height="26" transform="rotate(-30.72 34 294.303)" fill="#F6F0EC" />
                        <path d="M387.703 59.0856C387.803 59.4686 387.811 59.8655 387.728 60.2211C387.645 60.5767 387.474 60.8733 387.239 61.0696L382.43 65.1586L382.313 73.0804C382.305 73.4633 382.206 73.8169 382.029 74.0938C381.852 74.3706 381.606 74.5575 381.323 74.6291C381.04 74.7008 380.735 74.6538 380.447 74.4946C380.16 74.3354 379.904 74.0716 379.715 73.7385L375.839 66.8275L369.664 65.5233C369.365 65.4576 369.076 65.2763 368.835 65.0037C368.594 64.7311 368.413 64.3803 368.316 63.9985C368.219 63.6166 368.212 63.222 368.294 62.8676C368.376 62.5131 368.544 62.216 368.775 62.0159L373.584 57.928L373.701 50.0052C373.709 49.6222 373.808 49.2686 373.985 48.9917C374.161 48.7149 374.408 48.5281 374.691 48.4564C374.973 48.3847 375.279 48.4317 375.566 48.5909C375.854 48.7501 376.109 49.0139 376.298 49.347L380.175 56.2578L386.35 57.5622C386.65 57.6229 386.942 57.8022 387.184 58.0752C387.427 58.3482 387.608 58.7014 387.703 59.0856Z" fill="#750E21" />
                        <path d="M64.2472 295.969C64.3161 296.299 64.2806 296.642 64.1455 296.951C64.0104 297.26 63.7825 297.519 63.4934 297.692L57.572 301.3L56.5336 308.155C56.4811 308.487 56.3285 308.794 56.0962 309.036C55.8639 309.278 55.5632 309.443 55.2343 309.51C54.9054 309.576 54.5641 309.539 54.2564 309.406C53.9486 309.272 53.6891 309.048 53.5127 308.762L49.9068 302.839L43.0519 301.799C42.7205 301.747 42.4132 301.594 42.1711 301.362C41.9291 301.129 41.7639 300.828 41.6978 300.499C41.6317 300.17 41.6678 299.829 41.8014 299.521C41.9349 299.213 42.1594 298.954 42.4447 298.777L48.3663 295.171L49.4045 288.314C49.457 287.983 49.6097 287.676 49.8419 287.433C50.0742 287.191 50.375 287.026 50.7039 286.96C51.0328 286.894 51.374 286.93 51.6818 287.064C51.9895 287.197 52.249 287.422 52.4255 287.707L56.0324 293.631L62.8863 294.67C63.2199 294.719 63.5302 294.87 63.7741 295.102C64.018 295.335 64.1833 295.638 64.2472 295.969Z" fill="#750E21" />
                        <path d="M358.501 51.8407L355.333 51.247L353.388 47.9001C353.267 47.6912 353.1 47.5264 352.911 47.428C352.722 47.3296 352.52 47.3024 352.331 47.3501C352.143 47.3978 351.978 47.5181 351.859 47.6947C351.74 47.8712 351.672 48.0954 351.664 48.3369L351.548 52.2063L349.045 54.2369C348.889 54.3635 348.774 54.5514 348.716 54.775C348.658 54.9986 348.659 55.2471 348.72 55.4867C348.78 55.7263 348.897 55.9454 349.055 56.1142C349.213 56.2831 349.404 56.3935 349.601 56.4305L352.769 57.0242L354.714 60.3712C354.835 60.58 355.002 60.7449 355.191 60.8433C355.38 60.9417 355.582 60.9689 355.771 60.9212C355.959 60.8735 356.124 60.7532 356.243 60.5766C356.362 60.4001 356.43 60.1758 356.438 59.9344L356.554 56.065L359.057 54.0344C359.213 53.9077 359.328 53.7198 359.386 53.4962C359.444 53.2726 359.443 53.0242 359.382 52.7846C359.322 52.545 359.205 52.3259 359.047 52.157C358.889 51.9882 358.698 51.8777 358.501 51.8407ZM355.63 55.0143C355.563 55.0686 355.508 55.1418 355.469 55.2288C355.431 55.3158 355.409 55.4145 355.406 55.518L355.299 59.0618L353.518 55.9964C353.466 55.907 353.4 55.8305 353.324 55.7724C353.249 55.7142 353.166 55.6759 353.081 55.6601L350.18 55.1165L352.472 53.257C352.539 53.2027 352.594 53.1295 352.633 53.0425C352.671 52.9554 352.693 52.8567 352.696 52.7533L352.803 49.2095L354.584 52.2748C354.636 52.3643 354.702 52.4408 354.778 52.4989C354.853 52.557 354.936 52.5954 355.021 52.6112L357.922 53.1548L355.63 55.0143Z" fill="#750E21" />
                        <path d="M392.501 35.8407L389.333 35.247L387.388 31.9001C387.267 31.6912 387.1 31.5264 386.911 31.428C386.722 31.3296 386.52 31.3024 386.331 31.3501C386.143 31.3978 385.978 31.5181 385.859 31.6947C385.74 31.8712 385.672 32.0954 385.664 32.3369L385.548 36.2063L383.045 38.2369C382.889 38.3635 382.774 38.5514 382.716 38.775C382.658 38.9986 382.659 39.2471 382.72 39.4867C382.78 39.7263 382.897 39.9454 383.055 40.1142C383.213 40.2831 383.404 40.3935 383.601 40.4305L386.769 41.0242L388.714 44.3712C388.835 44.58 389.002 44.7449 389.191 44.8433C389.38 44.9417 389.582 44.9689 389.771 44.9212C389.959 44.8735 390.124 44.7532 390.243 44.5766C390.362 44.4001 390.43 44.1758 390.438 43.9344L390.554 40.065L393.057 38.0344C393.213 37.9077 393.328 37.7198 393.386 37.4962C393.444 37.2726 393.443 37.0242 393.382 36.7846C393.322 36.545 393.205 36.3259 393.047 36.157C392.889 35.9882 392.698 35.8777 392.501 35.8407ZM389.63 39.0143C389.563 39.0686 389.508 39.1418 389.469 39.2288C389.431 39.3158 389.409 39.4145 389.406 39.518L389.299 43.0618L387.518 39.9964C387.466 39.907 387.4 39.8305 387.324 39.7724C387.249 39.7142 387.166 39.6759 387.081 39.6601L384.18 39.1165L386.472 37.257C386.539 37.2027 386.594 37.1295 386.633 37.0425C386.671 36.9554 386.693 36.8567 386.696 36.7533L386.803 33.2095L388.584 36.2748C388.636 36.3643 388.702 36.4408 388.778 36.4989C388.853 36.557 388.936 36.5954 389.021 36.6112L391.922 37.1548L389.63 39.0143Z" fill="#750E21" />
                        <path d="M59.5008 269.841L56.3333 269.247L54.388 265.9C54.2666 265.691 54.1001 265.526 53.9111 265.428C53.7221 265.33 53.5197 265.302 53.3315 265.35C53.1432 265.398 52.9782 265.518 52.8589 265.695C52.7395 265.871 52.6716 266.095 52.6644 266.337L52.5478 270.206L50.0453 272.237C49.8891 272.364 49.7741 272.551 49.7158 272.775C49.6575 272.999 49.6588 273.247 49.7195 273.487C49.7802 273.726 49.8974 273.945 50.0552 274.114C50.2129 274.283 50.4035 274.394 50.6012 274.431L53.7687 275.024L55.714 278.371C55.8354 278.58 56.0019 278.745 56.1909 278.843C56.3799 278.942 56.5823 278.969 56.7705 278.921C56.9588 278.873 57.1238 278.753 57.2431 278.577C57.3625 278.4 57.4304 278.176 57.4376 277.934L57.5542 274.065L60.0567 272.034C60.2129 271.908 60.3279 271.72 60.3862 271.496C60.4445 271.273 60.4432 271.024 60.3825 270.785C60.3218 270.545 60.2046 270.326 60.0468 270.157C59.8891 269.988 59.6985 269.878 59.5008 269.841ZM56.6296 273.014C56.5627 273.069 56.508 273.142 56.4693 273.229C56.4307 273.316 56.4091 273.415 56.406 273.518L56.2993 277.062L54.5178 273.996C54.4659 273.907 54.3998 273.83 54.3244 273.772C54.249 273.714 54.1661 273.676 54.0814 273.66L51.1805 273.116L53.4724 271.257C53.5393 271.203 53.594 271.129 53.6326 271.042C53.6713 270.955 53.6929 270.857 53.696 270.753L53.8027 267.21L55.5842 270.275C55.6361 270.364 55.7022 270.441 55.7776 270.499C55.853 270.557 55.9359 270.595 56.0206 270.611L58.9215 271.155L56.6296 273.014Z" fill="#750E21" />
                        <path d="M37.5008 285.841L34.3333 285.247L32.388 281.9C32.2666 281.691 32.1001 281.526 31.9111 281.428C31.7221 281.33 31.5197 281.302 31.3315 281.35C31.1432 281.398 30.9782 281.518 30.8589 281.695C30.7395 281.871 30.6716 282.095 30.6644 282.337L30.5478 286.206L28.0453 288.237C27.8891 288.364 27.7741 288.551 27.7158 288.775C27.6575 288.999 27.6588 289.247 27.7195 289.487C27.7802 289.726 27.8974 289.945 28.0552 290.114C28.2129 290.283 28.4035 290.394 28.6012 290.431L31.7687 291.024L33.714 294.371C33.8354 294.58 34.0019 294.745 34.1909 294.843C34.3799 294.942 34.5823 294.969 34.7705 294.921C34.9588 294.873 35.1238 294.753 35.2431 294.577C35.3625 294.4 35.4304 294.176 35.4376 293.934L35.5542 290.065L38.0567 288.034C38.2129 287.908 38.3279 287.72 38.3862 287.496C38.4445 287.273 38.4432 287.024 38.3825 286.785C38.3218 286.545 38.2046 286.326 38.0468 286.157C37.8891 285.988 37.6985 285.878 37.5008 285.841ZM34.6296 289.014C34.5627 289.069 34.508 289.142 34.4693 289.229C34.4307 289.316 34.4091 289.415 34.406 289.518L34.2993 293.062L32.5178 289.996C32.4659 289.907 32.3998 289.83 32.3244 289.772C32.249 289.714 32.1661 289.676 32.0814 289.66L29.1805 289.116L31.4724 287.257C31.5393 287.203 31.594 287.129 31.6326 287.042C31.6713 286.955 31.6929 286.857 31.696 286.753L31.8027 283.21L33.5842 286.275C33.6361 286.364 33.7022 286.441 33.7776 286.499C33.853 286.557 33.9359 286.595 34.0206 286.611L36.9215 287.155L34.6296 289.014Z" fill="#750E21" />
                    </svg>

                    <div className="relative w-full h-full bg-cover bg-center"
                        style={{ backgroundImage: `url(${DTFPrintTech})`, width: "100%" }}></div>
                    <h3 className="text-[#9D2235] text-xl font-semibold mt-4">DTF Printing</h3>
                    <p className="text-gray-700 mt-2 text-sm px-3">
                        Sublimation printing uses heat to transfer vibrant, durable designs onto polyester fabrics and other surfaces.
                    </p>
                    <button className="my-4 px-[8px] py-[1px] text-[15px] border border-[#9D2235] rounded-lg text-black hover:bg-[#9D2235] hover:text-white transition">
                        Show Design
                    </button>
                </div>

                {/* Right Info Box */}
                <div className="flex flex-col w-full max-w-md text-white rounded-xl">
                    <div className="bg-[#750E21] text-center text-lg p-3 mb-3">
                        What does DTF printing mean ?
                    </div>
                    <div className="bg-transparent text-gray-800 p-5 border text-start border-[#9D2235]">
                        <p>
                            <span className="text-[#9D2235]">DTF Printing</span> is a modern technique used to print designs onto a transparent film, which is then transferred to fabrics using heat. This method is known for its ability to print intricate and colorful designs on a variety of fabrics, including cotton and polyester.
                        </p>
                    </div>
                </div>
            </div>

            {/* Divider */}
            <div className="flex items-center justify-center gap-4 w-full mt-10">
                <div className="w-[35%]  h-[5px] bg-[#9D2235]"></div>
                <svg
                    width="33"
                    height="44"
                    viewBox="0 0 33 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-10 lg:w-10 lg:h-12"
                >
                    <g filter="url(#filter0_d_633_99)">
                        <path d="M4.00005 18.128C3.99722 18.6457 4.10858 19.1519 4.31866 19.5761C4.52874 20.0003 4.82711 20.3214 5.17228 20.4949L12.2548 24.1351L14.8292 34.144C14.9561 34.6271 15.1846 35.0438 15.4839 35.3381C15.7833 35.6324 16.1392 35.7901 16.5038 35.7901C16.8684 35.7901 17.2243 35.6324 17.5237 35.3381C17.8231 35.0438 18.0515 34.6271 18.1784 34.144L20.7529 24.1336L27.8353 20.4949C28.1772 20.3155 28.472 19.9926 28.6802 19.5695C28.8884 19.1464 29 18.6433 29 18.128C29 17.6126 28.8884 17.1095 28.6802 16.6864C28.472 16.2633 28.1772 15.9404 27.8353 15.7611L20.7529 12.1224L18.1784 2.11196C18.0515 1.62879 17.8231 1.21208 17.5237 0.917814C17.2243 0.623548 16.8684 0.46582 16.5038 0.46582C16.1392 0.46582 15.7833 0.623548 15.4839 0.917814C15.1846 1.21208 14.9561 1.62879 14.8292 2.11196L12.2536 12.1224L5.17228 15.7611C4.82711 15.9345 4.52874 16.2557 4.31866 16.6798C4.10858 17.104 3.99722 17.6102 4.00005 18.128Z" fill="#750E21" />
                    </g>
                </svg>
                <svg
                    width="33"
                    height="44"
                    viewBox="0 0 33 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-10 lg:w-10 lg:h-12"
                >
                    <g filter="url(#filter0_d_633_99)">
                        <path d="M4.00005 18.128C3.99722 18.6457 4.10858 19.1519 4.31866 19.5761C4.52874 20.0003 4.82711 20.3214 5.17228 20.4949L12.2548 24.1351L14.8292 34.144C14.9561 34.6271 15.1846 35.0438 15.4839 35.3381C15.7833 35.6324 16.1392 35.7901 16.5038 35.7901C16.8684 35.7901 17.2243 35.6324 17.5237 35.3381C17.8231 35.0438 18.0515 34.6271 18.1784 34.144L20.7529 24.1336L27.8353 20.4949C28.1772 20.3155 28.472 19.9926 28.6802 19.5695C28.8884 19.1464 29 18.6433 29 18.128C29 17.6126 28.8884 17.1095 28.6802 16.6864C28.472 16.2633 28.1772 15.9404 27.8353 15.7611L20.7529 12.1224L18.1784 2.11196C18.0515 1.62879 17.8231 1.21208 17.5237 0.917814C17.2243 0.623548 16.8684 0.46582 16.5038 0.46582C16.1392 0.46582 15.7833 0.623548 15.4839 0.917814C15.1846 1.21208 14.9561 1.62879 14.8292 2.11196L12.2536 12.1224L5.17228 15.7611C4.82711 15.9345 4.52874 16.2557 4.31866 16.6798C4.10858 17.104 3.99722 17.6102 4.00005 18.128Z" fill="#750E21" />
                    </g>
                </svg>
                <svg
                    width="33"
                    height="44"
                    viewBox="0 0 33 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-10 lg:w-10 lg:h-12"
                >
                    <g filter="url(#filter0_d_633_99)">
                        <path d="M4.00005 18.128C3.99722 18.6457 4.10858 19.1519 4.31866 19.5761C4.52874 20.0003 4.82711 20.3214 5.17228 20.4949L12.2548 24.1351L14.8292 34.144C14.9561 34.6271 15.1846 35.0438 15.4839 35.3381C15.7833 35.6324 16.1392 35.7901 16.5038 35.7901C16.8684 35.7901 17.2243 35.6324 17.5237 35.3381C17.8231 35.0438 18.0515 34.6271 18.1784 34.144L20.7529 24.1336L27.8353 20.4949C28.1772 20.3155 28.472 19.9926 28.6802 19.5695C28.8884 19.1464 29 18.6433 29 18.128C29 17.6126 28.8884 17.1095 28.6802 16.6864C28.472 16.2633 28.1772 15.9404 27.8353 15.7611L20.7529 12.1224L18.1784 2.11196C18.0515 1.62879 17.8231 1.21208 17.5237 0.917814C17.2243 0.623548 16.8684 0.46582 16.5038 0.46582C16.1392 0.46582 15.7833 0.623548 15.4839 0.917814C15.1846 1.21208 14.9561 1.62879 14.8292 2.11196L12.2536 12.1224L5.17228 15.7611C4.82711 15.9345 4.52874 16.2557 4.31866 16.6798C4.10858 17.104 3.99722 17.6102 4.00005 18.128Z" fill="#750E21" />
                    </g>
                </svg>
                <div className="w-[35%] h-[5px] bg-[#9D2235]"></div>
            </div>
        </section>
    );
};

export default DTFPrintingTechniqSection;
