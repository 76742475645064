import SublimationProcessImg from "../../../Assets/Images/Printer/SublimationProcess.webp" 

const SublimationProcess = () => {
    const steps = [
      {
        number: "1",
        title: "Design Selection",
        description:
          "Choose the appropriate design, select a high-quality design that suits the product and is compatible with sublimation printing.",
      },
      {
        number: "2",
        title: "Printing Process",
        description:
          "Print the design onto the special sublimation paper. Use a sublimation printer to print the design in reverse onto sublimation paper.",
      },
      {
        number: "3",
        title: "Heat Transfer",
        description:
          "Transfer the design from the paper to the fabric using heat. Place the paper on the fabric and use a heat press to transfer the design, bonding the ink to the fabric permanently.",
      },
    ];
  
    return (
      <div className="py-16 px-6 lg:px-24">
        {/* Title */}
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-2xl lg:text-xl xl:text-3xl 2xl:text-4xl">
            Explore the complete Sublimation printing process, <br />
            from preparation to the final transfer
          </h2>
          <p className="text-[#767676] lg:text-[15px] xl:text-[20px] mt-2">
            We help you to provide the highest quality
          </p>
        </div>
  
        {/* Steps + Image Section */}
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left Section: Steps */}
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            {steps.map((step, index) => (
              <div key={index} className="flex gap-6 items-start">
                {/* Step Number */}
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 bg-[#750f1a] text-white rounded-full flex items-center justify-center text-lg font-bold">
                    {step.number}
                  </div>
                  {/* Line */}
                  {index !== steps.length - 1 && (
                    <div className="w-[2px] bg-[#767676] h-16 mt-2"></div>
                  )}
                </div>
  
                {/* Step Content */}
                <div>
                  <h3 className="lg:text-lg xl:text-2xl font-medium">{step.title}</h3>
                  <p className="text-sm md:text-base lg:text-sm xl:text-lg text-gray-600">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Right Section: Image */}
          <div className="w-full lg:w-1/2 hidden lg:flex justify-center">
            <div className="border border-[#750f1a] pt-2">
              <img
                src={SublimationProcessImg}
                alt="Sublimation Process"
                className="w-full h-auto -translate-x-2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default SublimationProcess;
  