import React, { useState } from "react";
import { motion } from "framer-motion";
import { SingleStar } from "../../../Assets/Svg/PrinterSvg";
import PrintingProcess from "../../../Assets/Images/Printer/PrintingProcess.webp";
import OpalDigitalPrinting from "../../../Assets/Video/OpalDigitalPrinting.mp4";

const PrintingProcessSection = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <section className="py-6 px-6 lg:px-12 text-center relative gap-x-64">
            {/* Heading */}
            <div className="text-center w-auto">
                <p className="text-xl lg:text-2xl font-semibold">
                    <div className="absolute top-4 sm:left-[3rem] md:left-[14rem] lg:left-[20rem] xl:left-[33rem] 2xl:left-[48rem] w-8 h-10 lg:w-10 lg:h-12">
                        <SingleStar />
                    </div>
                    Watch the Printing Process
                </p>
                <p className="text-xl lg:text-2xl font-semibold text-[#8A202E]">
                    <span className="text-[#8A202E]">Step{" "}</span>
                    <span className="text-black">by{" "}</span>
                    <span className="text-[#8A202E]">Step</span>!
                </p>
            </div>

            {/* Content Section */}
            <div className="mt-8 flex flex-col lg:flex-row justify-center items-center gap-44 xl:gap-60">
                {/* Left Side - Text Box */}
                <div className="border-2 w-1/2 border-[#8A202E] p-2 max-w-lg 3xl:max-w-[42rem] text-[#4A4A4A] text-[16px] lg:text-[18px] leading-relaxed hidden lg:flex items-center justify-center">
                    <div className="border-2 border-[#8A202E] p-6 text-[#4A4A4A] text-[16px] lg:text-[15px] xl:text-[15px] 3xl:text-[20px] leading-relaxed">
                        Discover the journey of print production! From the initial design
                        concept to the final printed product, watch as every step of the
                        process comes together. Our video takes you behind the scenes to
                        showcase how we use the latest printing technology to bring your
                        designs to life with precision and quality.
                    </div>
                </div>

                {/* Right Side - Video Section */}
                <div className="relative w-80 3xl:w-[23rem] flex justify-center items-center">

                    {/* Arched Image Container */}
                    <motion.div
                        className="relative w-3/4 h-80 p-2 border-2 border-[#8A202E] rounded-tl-[9.5rem] rounded-tr-[9.5rem] overflow-hidden cursor-pointer"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => setIsModalOpen(true)}
                    >
                        {isHovered ? (
                            <video
                                src={OpalDigitalPrinting}
                                poster={PrintingProcess}
                                autoPlay
                                loop
                                preload="metadata"
                                controls
                                className="w-full h-full object-cover rounded-tl-[9.5rem] rounded-tr-[9.5rem]"
                            />
                        ) : (
                            <div
                                className="w-full h-full bg-cover bg-center rounded-tl-[9.5rem] rounded-tr-[9.5rem]"
                                style={{ backgroundImage: `url(${PrintingProcess})` }}
                            />
                        )}
                    </motion.div>
                    {/* Decorative Top Element */}
                    <div className="absolute -top-[10px] left-1/2 -translate-x-1/2 w-6 h-6 text-[#8A202E]">
                        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="bg-transparent">
                            <g filter="url(#filter0_d_635_103)">
                                <path d="M15.9999 8.97002C16.0013 9.21854 15.9479 9.4615 15.847 9.66511C15.7462 9.86872 15.603 10.0229 15.4373 10.1061L12.0377 11.8535L10.802 16.6577C10.741 16.8896 10.6314 17.0896 10.4877 17.2309C10.344 17.3721 10.1732 17.4478 9.99814 17.4478C9.82312 17.4478 9.65228 17.3721 9.50859 17.2309C9.3649 17.0896 9.25523 16.8896 9.19433 16.6577L7.9586 11.8527L4.55901 10.1061C4.39492 10.02 4.2534 9.86504 4.15347 9.66195C4.05353 9.45885 3.99997 9.2174 3.99997 8.97002C3.99997 8.72263 4.05353 8.48118 4.15347 8.27808C4.2534 8.07499 4.39492 7.91999 4.55901 7.8339L7.9586 6.08732L9.19433 1.28233C9.25523 1.05041 9.3649 0.850392 9.50859 0.709145C9.65228 0.567897 9.82312 0.492187 9.99814 0.492188C10.1732 0.492187 10.344 0.567897 10.4877 0.709145C10.6314 0.850392 10.741 1.05041 10.802 1.28233L12.0382 6.08732L15.4373 7.8339C15.603 7.91716 15.7462 8.07131 15.847 8.27492C15.9479 8.47853 16.0013 8.72149 15.9999 8.97002Z" fill="#750E21" />
                            </g>
                            <defs>
                                <filter id="filter0_d_635_103" x="-3.05176e-05" y="0.492188" width="20" height="24.9561" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_635_103" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_635_103" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    {/* Circular Play Button */}
                    <div className="absolute top-[1rem] left-[0.5rem] w-24 h-24 flex justify-center items-center">
                        {/* Rotating Circular Text */}
                        <svg viewBox="0 0 100 100" className="absolute w-full h-full">
                            <path id="circlePath" d="M 50,50 m -35,0 a 35,35 0 1,1 70,0 a 35,35 0 1,1 -70,0" fill="none" />
                            <text className="text-[10px] uppercase tracking-widest fill-black font-semibold">
                                <textPath href="#circlePath" startOffset="50%" textAnchor="middle">
                                    * PLAY VIDEO * PLAY VIDEO *
                                </textPath>
                            </text>
                        </svg>

                        {/* Play Button in Center */}
                        <motion.button
                            className="absolute w-[3.5rem] h-[3.5rem] bg-white rounded-full flex justify-center items-center shadow-lg"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <svg className="w-8 h-8 text-black" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M8 5v14l11-7z" />
                            </svg>
                        </motion.button>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50"
                    onClick={() => setIsModalOpen(false)} // Closes modal when clicking the background
                >
                    <div className="relative w-[90%] md:w-[70%] lg:w-[50%] max-w-4xl" onClick={(e) => e.stopPropagation()}>
                        <video
                            src={OpalDigitalPrinting}
                            autoPlay
                            controls
                            className="w-full rounded-lg"
                        />
                    </div>
                </div>
            )}
        </section>
    );
};

export default PrintingProcessSection;
