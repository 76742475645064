import { useState, useEffect, useRef } from "react";

const useCountUpOnScroll = (threshold = 0.5) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setStartAnimation(true);
                    observer.disconnect(); // Stop observing once triggered
                }
            },
            { threshold }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, [threshold]);

    return { ref, startAnimation };
};

export default useCountUpOnScroll;
