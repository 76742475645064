import React from "react";
import GroupDigital1 from "../../../Assets/Images/changing/GroupDigital1.webp";
import GroupDigital2 from "../../../Assets/Images/changing/GroupDigital2.webp";
import GroupDigital3 from "../../../Assets/Images/changing/GroupDigital3.webp";
import GroupDigital4 from "../../../Assets/Images/changing/GroupDigital4.webp";
import GroupDigital5 from "../../../Assets/Images/changing/GroupDigital5.webp";
import GroupDigital6 from "../../../Assets/Images/changing/GroupDigital6.webp";

const GroupDigitalPrinting = () => {
  return (
    <section className="w-[90%] mx-auto bg-white py-6">
      {/* Top Section */}
      <div className="grid grid-cols-2 md:grid-cols-2 items-center">
        {/* Left Side Image */}
        <div className="hidden md:flex h-[186px] xl:h-[250px] 2xl:h-[325px] rounded-tl-[170px] overflow-hidden">
          <img
            src={GroupDigital1}
            alt="Group Digital"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Right Side Content */}
        <div
          className="hidden md:flex flex-col justify-center space-y-8 px-8 text-white bg-cover bg-center rounded-lg w-full h-[186px] xl:h-[250px] 2xl:h-[325px]"
          style={{
            backgroundImage: `url(${GroupDigital2})`,
          }}
        >
          <h2 className="text-[#000000] text-3xl md:text-[32px] lg:text-[29px] 2xl:text-[31px] lg:my-2 xl:my-7">
            Group Digital<br/> Printing
          </h2>
          <span className="text-[#000000] lg:text-[15px] xl:text-[18px] 2xl:text-[21px]">
            Connecting businesses with the best<br/>designs and creative printing.
          </span>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="w-[19rem] md:w-full grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 mx-auto md:mx-0">
        {/* Cards */}
        {[
          { title: "Winter - DTF", img: GroupDigital3 },
          { title: "Winter - Digital", img: GroupDigital4 },
          { title: "Winter - Digital", img: GroupDigital5 },
          { title: "Winter - Digital", img: GroupDigital6 },
        ].map((card, index) => (
          <div
            key={index}
            className="bg-white shadow-md overflow-hidden rounded-lg"
          >
            <img
              src={card.img}
              alt={card.title}
              className="w-full h-[244px] md:h-[164px] lg:h-[164px] xl:h-[200px] 2xl:h-[280px] object-cover"
            />
            <div className="p-3">
              <p className="text-center text-base 2xl:text-[20px] font-medium text-gray-700">
                {card.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GroupDigitalPrinting;
