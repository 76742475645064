import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import TrendPage from '../Pages/Trend';
import AboutPage from '../Pages/AboutUs';
import Printer from '../Pages/Printer';
import DesignsPage from '../Pages/DesignPage';
import ProductDetail from '../Pages/ProductDetail';
import PersonalInfo from '../Pages/PersonInfo';
import ShoppingCart from '../Pages/ShoppingCart';
import Pricing from '../Pages/Pricing';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/trends" element={<TrendPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/printer" element={<Printer />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/studio/floral" element={<DesignsPage />} />
      <Route path="/details" element={<ProductDetail />} />
      <Route path="/PersonalInfo" element={<PersonalInfo />} />
      <Route path="/ShoppingCart" element={<ShoppingCart />} />
    </Routes>
  );
};

export default RoutesConfig;
