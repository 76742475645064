import SublimationPrintingImg from "../../../Assets/Images/Printer/SublimationPrinting.webp";
import TrendStars from "../../../Assets/Images/TrendStars.png";

const SublimationPrinting = () => {
    return (
        <section className="py-5 px-6 md:px-12 lg:px-24">
            {/* Title Section */}
            <div className="text-center">
                <h2 className="text-xl xl:text-2xl 2xl:text-3xl text-[#000] mb-4">
                    Discover Our Sublimation Printing Methods !<img src={TrendStars} alt="Trend Stars" loading="lazy" className="inline-block -translate-y-3 ml-3" />
                </h2>
                <p className="text-sm 2xl:text-xl text-gray-500">
                    Choose the right method to bring your designs to life
                </p>
            </div>

            {/* Horizontal Line */}
            <div className="w-full border-t-4 border-[#750E21] my-6"></div>

            {/* Main Content */}
            <div className="flex flex-col lg:flex-row items-center gap-10">
                {/* Left Text Section */}
                <div className="lg:w-1/2 text-left">
                    <h3 className="text-2xl sm:w-[75%] 2sm:w-[65%] md:w-[45%] lg:w-[65%] xl:w-[51%] 2xl:w-[60%] md:text-3xl lg:text-4xl xl:text-[31px] 2xl:text-5xl 3xl:text-5xl bg-gradient-to-r from-black via-[#B1354C] via-[#D72323] via-[#871F32] to-[#750E21] bg-clip-text text-transparent drop-shadow-lg">
                        <span className="">What is Sublimation</span>
                        <span className="inline-block w-full text-right">Printing?</span>
                    </h3>
                    <p className="text-lg font-semibold my-10 text-[#750E21]">{">>>"}</p>

                    <p className="mt-4 text-gray-700 leading-7 xl:text-lg 2xl:text-2xl text-justify">
                        <span className="text-[#750E21] font-medium">Sublimation printing</span> is a digital printing technique used to convert ink directly from a liquid to a gas without passing through the solid state. This process produces high-quality images with vibrant colors and excellent precision, and is commonly used for printing on apparel, mugs, gifts, and various other products.
                    </p>
                </div>
                {/* Vertical Line */}
                <div className="w-4 h-80 border-r-4 border-[#750E21] my-6 hidden lg:block"></div>
                {/* Right Image Section */}
                <div className="lg:w-1/2 relative hidden lg:block">
                    <div className="p-4 rounded-xl">
                        {/* Responsive Image */}
                        <picture>
                            <source srcSet={SublimationPrintingImg} type="image/webp" />
                            <img
                                src={SublimationPrintingImg}
                                alt="Sublimation Printing Example"
                                loading="lazy"
                                className="rounded-lg w-80 h-[25rem] object-cover mx-auto"
                            />
                        </picture>
                    </div>
                </div>

            </div>

            {/* Bottom Horizontal Line */}
            <div className="w-full border-t-4 border-[#750E21] my-6"></div>
        </section>
    );
};

export default SublimationPrinting;
