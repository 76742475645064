import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider1 from '../../../Assets/Images/Trend/Slider1.webp';
import Slider2 from '../../../Assets/Images/Trend/Slider2.webp';
import Slider3 from '../../../Assets/Images/Trend/Slider3.webp';
import Slider4 from '../../../Assets/Images/Trend/Slider4.webp';

const ImageSlider = () => {
    const images = [Slider1, Slider2, Slider3, Slider4];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Default number of slides for large screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024, // For screens smaller than 1024px
                settings: {
                    slidesToShow: 3, // Show 3 slides
                },
            },
            {
                breakpoint: 768, // For screens smaller than 768px
                settings: {
                    slidesToShow: 2, // Show 2 slides
                },
            },
            {
                breakpoint: 480, // For screens smaller than 480px
                settings: {
                    slidesToShow: 1, // Show 1 slide
                },
            },
        ],
    };

    return (
        <div className="bg-transparent flex items-center justify-center px-4 py-8">
            <div className="w-full max-w-6xl mx-auto">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="px-2">
                            <img
                                src={image}
                                alt={`Slide ${index + 1}`}
                                className="w-full h-[225px] object-cover rounded-md"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
