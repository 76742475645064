import React from "react";
import TopImage from "../../../Assets/Images/About/TopImage.webp";
import BottomImage from "../../../Assets/Images/About/BottomImage.webp";

const Conclusion = () => {
    return (
        <section className="w-full px-6 py-12 bg-white">
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-6">
                    <h2 className="lg:text-[24px] xl:text-[29px] text-justify font-bold lg:font-semibold text-gray-800">
                        Wondering what’s next in textile fashion? Let us help you discover the perfect design and printing solutions for your next collection
                    </h2>
                    <br />
                    <p className="md:text-[17px] lg:text-lg 2xl:text-xl text-gray-600 text-justify leading-relaxed">
                        Explore the latest trends in fabrics and prints, and create designs that make a statement
                    </p>
                </div>

                {/* Right Column */}
                <div className="flex flex-col items-center space-y-2 xl:space-y-6">
                    {/* Top Image */}
                    <img
                        src={TopImage}
                        alt="Top Design"
                        className="w-full max-w-[25rem] md:max-w-md 2xl:max-w-lg object-cover rounded-lg"
                    />
                    {/* Bottom Image */}
                    <img
                        src={BottomImage}
                        alt="Bottom Design"
                        className="w-full max-w-[25rem] md:max-w-md 2xl:max-w-lg object-cover rounded-lg"
                    />
                </div>
            </div>
        </section>
    );
};

export default Conclusion;
