import React from "react";

const BgHeroSection = () => (
    <svg viewBox="0 0 1440 456" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[1055px] lg:w-full h-[437px] lg:h-full">
        <path d="M0 0L1440 334.202V456H0V0Z" fill="url(#paint0_linear_435_355)" />
        <defs>
            <linearGradient id="paint0_linear_435_355" x1="655.878" y1="5.19517e-06" x2="656.699" y2="456.068" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FDFCFB" />
                <stop offset="1" stopColor="#E2D1C3" stopOpacity="0.6" />
            </linearGradient>
        </defs>
    </svg>
);

const BgHeroSection1 = () => (
    <svg viewBox="0 0 1440 429" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[1055px] lg:w-full h-full z-0">
        <path d="M0 429L1440 114.586V0H0V429Z" fill="url(#paint0_linear_456_432)" />
        <defs>
            <linearGradient id="paint0_linear_456_432" x1="655.878" y1="429" x2="656.605" y2="-0.0639375" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FDFCFB" />
                <stop offset="1" stopColor="#E2D1C3" stopOpacity="0.6" />
            </linearGradient>
        </defs>
    </svg>
);

const PatternStockText = () => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="w-28 h-28 hidden md:block">
        <circle cx="40" cy="41" r="19.5" stroke="#750E21" />
        <circle cx="40.2725" cy="40.3887" r="39.5" stroke="#750E21" className="mx-auto" />
        <path d="M74.355 42.8183C74.2758 43.2484 74.1712 43.9342 74.0412 44.8755C73.9093 45.8274 73.7729 46.6862 73.6318 47.452C73.3324 49.078 72.799 50.1782 72.0316 50.7527C71.2746 51.3292 70.3297 51.5131 69.1968 51.3044C68.1897 51.119 67.4283 50.5124 66.9126 49.4847C66.3949 48.4674 66.2501 47.3399 66.478 46.102C66.5959 45.4621 66.7268 44.9276 66.8709 44.4986L61.93 43.5887L61.5089 44.9917C61.3439 45.0047 61.1498 44.9689 60.9267 44.8845C61.0799 44.2294 61.2328 43.4875 61.3854 42.6587C61.4994 42.0398 61.6271 41.2878 61.7684 40.4028C61.9688 40.3746 62.1576 40.4094 62.3348 40.5071L62.2831 41.8479C62.674 41.9633 63.2838 42.0973 64.1126 42.2499L73.3807 43.9567L73.7728 42.7111C73.9379 42.6981 74.132 42.7339 74.355 42.8183ZM71.1981 45.2956L67.5161 44.6175C67.4142 44.9349 67.3063 45.4031 67.1923 46.0221C67.0919 46.5675 67.0951 47.0508 67.2019 47.4718C67.3173 47.9052 67.5088 48.2495 67.7763 48.5049C68.0543 48.7622 68.3429 48.9617 68.6422 49.1036C68.9414 49.2455 69.2694 49.3493 69.6261 49.415C70.5387 49.583 71.2841 49.4817 71.8621 49.1109C72.4507 48.7421 72.8444 48.0174 73.0434 46.9369C73.1149 46.5488 73.1496 46.1539 73.1477 45.7522C72.5613 45.5791 71.9115 45.4269 71.1981 45.2956Z" fill="black" />
        <path d="M60.3547 50.5565L59.5073 51.55C59.3503 51.4975 59.1856 51.3888 59.0132 51.2239C59.4101 50.6807 59.7349 50.2177 59.9875 49.8349C60.1286 49.6212 60.437 49.1346 60.9127 48.3751C61.1082 48.4274 61.2685 48.5332 61.3935 48.6924L60.972 49.6795C61.0916 49.9629 61.3703 50.4024 61.8079 50.998L68.7478 60.5248L68.4393 60.9922L55.4542 57.9811L54.6068 58.9746C54.4497 58.922 54.2851 58.8133 54.1127 58.6484C54.5096 58.1052 54.9372 57.4864 55.3955 56.792C55.7363 56.2757 56.1446 55.6378 56.6204 54.8782C56.8159 54.9306 56.9761 55.0363 57.1011 55.1955L56.5298 56.4096L60.6037 57.4115L62.8953 53.9396L60.3547 50.5565ZM66.8108 59.0737L66.8196 59.0604C66.4717 58.6774 66.0388 58.1424 65.5209 57.4555L63.4044 54.6207L61.4301 57.6119L64.851 58.4703C65.5174 58.6418 66.1707 58.8429 66.8108 59.0737Z" fill="black" />
        <path d="M62.3339 67.932L52.9684 73.8076L50.9821 70.6716C51.1582 70.5108 51.3254 70.4059 51.4836 70.357L53.6597 72.4106L56.4788 70.642L50.5182 61.1409L49.0643 61.8642C48.9431 61.7513 48.8382 61.5842 48.7497 61.3627C49.3359 61.0327 50.0311 60.6154 50.8353 60.1109C51.4588 59.7198 52.1443 59.2771 52.892 58.7828C53.0483 58.9115 53.1503 59.0741 53.1981 59.2708L52.0397 60.2242C52.2189 60.5902 52.5325 61.1302 52.9803 61.844L57.929 69.7322L60.7753 67.9465L59.8733 65.0937C59.9861 64.9725 60.1533 64.8676 60.3747 64.7791L62.3339 67.932Z" fill="black" />
        <path d="M49.0854 75.1848L38.0983 76.4179L37.6684 72.7308C37.8969 72.6623 38.093 72.6403 38.2567 72.6648L39.327 75.4589L42.6343 75.0877L41.3833 63.9417L39.7596 63.9629C39.6993 63.8087 39.6773 63.6126 39.6936 63.3746C40.3649 63.3315 41.1723 63.2569 42.1157 63.1511C42.8471 63.069 43.6569 62.9674 44.5449 62.8462C44.6299 63.0299 44.6513 63.2207 44.6091 63.4186L43.1518 63.7754C43.1546 64.183 43.203 64.8054 43.297 65.6429L44.3356 74.8968L47.6746 74.522L48.0989 71.5602C48.2532 71.5 48.4493 71.478 48.6873 71.4942L49.0854 75.1848Z" fill="black" />
        <path d="M35.6256 65.7253L32.6526 74.6849L33.8272 75.2601C33.8275 75.4625 33.767 75.6447 33.6458 75.8068L31.5805 75.0709L25.4454 73.0352L26.5992 69.5071C26.8354 69.5405 27.0227 69.6026 27.1611 69.6935L26.9628 72.679L31.063 74.0395L32.5747 69.4837L30.1298 68.7231L29.2867 70.0954C29.1214 70.0855 28.9341 70.0233 28.7248 69.9089C28.9535 69.3554 29.1904 68.7092 29.4356 67.9701C29.6607 67.2918 29.8638 66.6287 30.0451 65.9808C30.2475 65.9806 30.4297 66.041 30.5918 66.1622L30.4018 67.8524L32.8316 68.7092L34.56 63.5005L30.0042 61.9888L28.3785 64.5006C28.2132 64.4907 28.0259 64.4286 27.8166 64.3142L29.0008 60.7961L35.5915 62.983L37.7781 63.658C37.7784 63.8604 37.718 64.0426 37.5967 64.2047L36.2809 63.9535C36.0691 64.4564 35.8506 65.047 35.6256 65.7253Z" fill="black" />
        <path d="M20.743 70.5569C20.4214 70.2604 19.8972 69.8062 19.1702 69.1942C18.4354 68.5749 17.7818 68.0014 17.2092 67.4736C16.0171 66.3748 15.3866 65.3512 15.3178 64.4028C15.2418 63.4623 15.5942 62.5685 16.3749 61.7215C16.8449 61.2117 17.4442 60.9155 18.1731 60.833C18.902 60.7505 19.6467 60.8712 20.4072 61.195C20.5977 60.3116 20.7792 59.4852 20.9517 58.7157C21.1163 57.9391 21.244 57.3677 21.3348 57.0017C21.4256 56.6356 21.5064 56.304 21.5774 56.0067C21.6411 55.7173 21.6819 55.5155 21.6999 55.4016C21.7101 55.2804 21.7133 55.1746 21.7097 55.0841L20.9711 54.1857C21.0481 54.0392 21.1819 53.8941 21.3723 53.7504L23.3723 55.5939L22.1774 60.4549C22.0006 61.1188 21.9061 61.67 21.8937 62.1083L22.0584 62.2601C22.4584 62.6288 22.8406 63.0246 23.2051 63.4476L26.7186 59.6359L25.7565 58.5315C25.8335 58.3849 25.9673 58.2398 26.1577 58.0962C26.6301 58.5752 27.1761 59.1002 27.7957 59.6713C28.2585 60.0979 28.8265 60.6069 29.4997 61.1985C29.4167 61.3831 29.2865 61.5242 29.1094 61.622L28.0028 60.8632C27.6967 61.1323 27.2581 61.5766 26.6869 62.1962L20.2997 69.1255L21.1442 70.1216C21.0672 70.2681 20.9334 70.4132 20.743 70.5569ZM20.3314 66.5652L22.7605 63.93C22.5463 63.6745 22.2078 63.3335 21.745 62.907C21.3451 62.5382 20.946 62.2865 20.548 62.1517C20.1349 62.0176 19.7602 61.9913 19.4238 62.073C19.0802 62.1624 18.7722 62.292 18.4998 62.4616C18.2274 62.6312 17.9756 62.8415 17.7442 63.0925C17.1225 63.767 16.8068 64.4479 16.797 65.1352C16.7872 65.8225 17.1745 66.5277 17.9588 67.2506C18.249 67.5181 18.5645 67.7582 18.9053 67.9707C19.3644 67.5671 19.8398 67.0986 20.3314 66.5652Z" fill="black" />
        <path d="M14.4738 46.9548L9.04272 48.9675C8.25256 49.2603 7.67486 49.4971 7.30961 49.6779L7.64662 51.1399C7.48882 51.2667 7.30879 51.3334 7.10651 51.3401C6.90301 50.6988 6.69192 50.0832 6.47324 49.493C6.23232 48.8429 5.98808 48.2452 5.74051 47.6999C5.94572 47.5784 6.13076 47.5098 6.29562 47.4942L6.95158 48.8039L18.1888 44.6397L18.4835 45.4348L11.9785 55.3703C11.6833 55.8323 11.2835 56.3957 10.7791 57.0604C11.2206 56.8399 11.7665 56.6092 12.4166 56.3683L18.8679 53.9776C19.658 53.6848 20.2357 53.448 20.601 53.2671L20.264 51.8051C20.4218 51.6784 20.6018 51.6117 20.8041 51.605C21.1028 52.4725 21.3189 53.0864 21.4524 53.4464C21.671 54.0366 21.9103 54.6361 22.1701 55.2451C21.9649 55.3667 21.7798 55.4353 21.615 55.4508L20.959 54.1412L12.1222 57.4158C11.3321 57.7086 10.7544 57.9455 10.3891 58.1263L10.6706 59.4383C10.5228 59.5613 10.3527 59.6243 10.1604 59.6273L9.07075 56.6867L15.033 47.6349C15.1133 47.5141 15.2119 47.3809 15.3285 47.2353C15.4415 47.0797 15.5826 46.908 15.7519 46.7201C15.9174 46.5223 16.0423 46.3679 16.1263 46.2571C15.5448 46.5295 14.9939 46.7621 14.4738 46.9548Z" fill="black" />
        <path d="M17.0653 41.455C17.5455 40.8769 17.8356 40.0301 17.9356 38.9146C18.0032 38.1602 17.8358 37.5776 17.4336 37.1668C17.0322 36.7453 16.5288 36.5074 15.9232 36.4531C15.6045 36.4246 15.3365 36.4273 15.1193 36.4614C14.8914 36.4945 14.6313 36.589 14.3389 36.7449C14.0474 36.8901 13.7464 37.1416 13.4359 37.4993C13.1254 37.857 12.7885 38.3301 12.4252 38.9187C11.9992 39.6088 11.6069 40.1627 11.2482 40.5803C10.8895 40.998 10.5301 41.3031 10.1702 41.4957C9.81021 41.6884 9.47285 41.8081 9.15809 41.8548C8.84332 41.9016 8.45752 41.9045 8.00069 41.8636C7.06577 41.7798 6.37665 41.3432 5.93334 40.5538C5.48036 39.7529 5.29957 38.8424 5.39096 37.8225C5.50426 36.5582 5.93247 35.365 6.6756 34.2429L9.73534 34.5171C9.76671 34.884 9.73958 35.1868 9.65393 35.4254L6.85128 35.6883C6.41851 36.335 6.16451 37.0779 6.0893 37.9172C6.03884 38.4803 6.16598 38.9736 6.47073 39.3972C6.7658 39.8092 7.21612 40.0423 7.82169 40.0965C8.16166 40.127 8.42917 40.1296 8.62421 40.1042C8.81925 40.0788 9.05719 39.9931 9.33803 39.8469C9.60824 39.6998 9.89376 39.4415 10.1946 39.0723C10.4848 38.702 10.8188 38.2018 11.1965 37.5717C12.5052 35.3972 13.9244 34.3784 15.4543 34.5155C16.538 34.6126 17.3773 35.1055 17.9724 35.9942C18.5674 36.8828 18.805 37.9965 18.685 39.3351C18.566 40.6631 18.1431 41.8568 17.4163 42.9162L14.3566 42.642C14.3252 42.2751 14.3523 41.9723 14.438 41.7336L17.0653 41.455Z" fill="black" />
        <path d="M19.8628 29.3372C20.5666 29.0736 21.2387 28.4824 21.8791 27.5635C22.3122 26.9422 22.456 26.3534 22.3107 25.797C22.1715 25.2319 21.8524 24.7756 21.3536 24.4279C21.0911 24.2449 20.8571 24.1143 20.6516 24.0361C20.4373 23.9518 20.1646 23.9047 19.8334 23.8949C19.5083 23.8764 19.1222 23.9453 18.6751 24.1018C18.228 24.2582 17.7007 24.5018 17.0931 24.8325C16.3809 25.2202 15.7654 25.5063 15.2467 25.6909C14.728 25.8755 14.2646 25.962 13.8565 25.9506C13.4484 25.9392 13.0961 25.8757 12.7996 25.7601C12.5032 25.6445 12.1668 25.4556 11.7905 25.1933C11.0204 24.6566 10.6388 23.9356 10.6457 23.0302C10.6499 22.1101 10.9448 21.2299 11.5303 20.3898C12.2561 19.3485 13.22 18.5251 14.4221 17.9197L16.9423 19.6762C16.7875 20.0103 16.6137 20.2597 16.4209 20.4244L13.8573 19.2618C13.1606 19.6084 12.5714 20.1273 12.0896 20.8186C11.7663 21.2824 11.6319 21.7738 11.6863 22.2928C11.738 22.7969 12.0132 23.2228 12.512 23.5704C12.7921 23.7656 13.023 23.9005 13.2049 23.9753C13.3869 24.0501 13.636 24.0937 13.9524 24.1062C14.26 24.1125 14.636 24.0301 15.0804 23.8588C15.5161 23.6814 16.0543 23.4129 16.695 23.0533C18.9103 21.8149 20.648 21.6348 21.9081 22.5131C22.8007 23.1352 23.2848 23.9796 23.3604 25.0464C23.436 26.1132 23.0895 27.198 22.3211 28.3006C21.5587 29.3945 20.5991 30.2209 19.4424 30.7799L16.9221 29.0234C17.077 28.6893 17.2508 28.4398 17.4436 28.2752L19.8628 29.3372Z" fill="black" />
        <path d="M17.4362 13.5368L26.8453 7.7314L28.8081 10.8821C28.6308 11.0416 28.4628 11.1452 28.3043 11.1929L26.1436 9.12317L23.3113 10.8707L29.2007 20.416L30.6601 19.7036C30.7804 19.8174 30.884 19.9853 30.9709 20.2075C30.3822 20.5331 29.6839 20.9451 28.876 21.4436C28.2496 21.8301 27.5607 22.2676 26.8094 22.7563C26.6541 22.6265 26.5533 22.4631 26.5069 22.2661L27.6725 21.3213C27.496 20.954 27.1865 20.4117 26.744 19.6946L21.8543 11.7696L18.9948 13.5339L19.8755 16.3934C19.7617 16.5138 19.5938 16.6174 19.3717 16.7043L17.4362 13.5368Z" fill="black" />
        <path d="M37.8462 5.31151C38.8904 5.26139 39.8369 5.40284 40.6858 5.73586C41.5348 6.06888 42.23 6.53742 42.7716 7.14148C43.3127 7.73489 43.7348 8.40876 44.038 9.16309C44.3513 9.90626 44.5279 10.6934 44.5678 11.5244C44.6599 13.4422 44.1356 15.0906 42.9951 16.4695C41.8545 17.8484 40.352 18.5826 38.4875 18.6721C37.454 18.7217 36.5128 18.58 35.6639 18.247C34.8149 17.914 34.1199 17.4508 33.5788 16.8574C33.0373 16.2533 32.6098 15.5797 32.2965 14.8365C31.9938 14.0928 31.8228 13.3108 31.7834 12.4904C31.7246 11.2652 31.9365 10.1177 32.4191 9.04798C32.9124 7.97777 33.634 7.10484 34.5841 6.42918C35.5442 5.74236 36.6316 5.3698 37.8462 5.31151ZM38.4293 17.7939C39.4308 17.7458 40.2641 17.4175 40.929 16.8089C41.604 16.1892 42.0695 15.43 42.3254 14.5314C42.5919 13.6322 42.6991 12.6393 42.647 11.5525C42.5974 10.5191 42.3986 9.60486 42.0508 8.80996C41.7131 8.00389 41.1854 7.35644 40.4679 6.86761C39.7497 6.36813 38.8846 6.14268 37.8724 6.19126C36.4128 6.26132 35.3303 6.84722 34.625 7.94897C33.9196 9.05072 33.6053 10.4007 33.682 11.9988C33.7347 13.0962 33.9314 14.0799 34.2722 14.9499C34.6125 15.8093 35.1427 16.51 35.8628 17.0521C36.583 17.5942 37.4385 17.8415 38.4293 17.7939Z" fill="black" />
        <path d="M54.5938 20.6984C54.7002 20.8863 54.7293 21.1302 54.681 21.4299C52.7248 21.9725 51.0707 21.8907 49.7187 21.1845C48.7922 20.7005 48.0703 20.1128 47.5529 19.4214C47.0405 18.7205 46.7006 17.9774 46.5333 17.192C46.3708 16.3971 46.3589 15.5906 46.4974 14.7724C46.6503 13.9498 46.9194 13.1697 47.3046 12.4322C47.6948 11.6853 48.2027 11.024 48.8283 10.4482C49.4589 9.86303 50.1657 9.41992 50.9487 9.11891C51.7367 8.80844 52.5788 8.67069 53.4752 8.70565C54.381 8.74555 55.2971 9.00749 56.2237 9.49147C57.4433 10.1285 58.4297 11.0048 59.1828 12.1202L57.7605 14.8432C57.5755 14.7826 57.4169 14.7178 57.2845 14.6487C57.1427 14.5746 57.0319 14.4987 56.9522 14.4209L57.7355 11.8155C57.3563 11.1361 56.6845 10.5445 55.7201 10.0408C55.1245 9.72963 54.514 9.58522 53.8886 9.60754C53.2776 9.62534 52.6809 9.77699 52.0985 10.0625C51.5306 10.3434 50.9938 10.749 50.4882 11.2792C49.9876 11.7999 49.5545 12.4101 49.1891 13.1097C48.7989 13.8566 48.5235 14.5912 48.3628 15.3136C48.2116 16.0409 48.1757 16.7201 48.2552 17.3513C48.3346 17.9825 48.5321 18.549 48.8477 19.0507C49.1776 19.548 49.6262 19.9448 50.1935 20.2411C50.8742 20.5967 51.6004 20.8075 52.3722 20.8737C53.1439 20.9399 53.8845 20.8814 54.5938 20.6984Z" fill="black" />
        <path d="M62.4915 22.4987L60.7786 28.3891C60.5315 29.1459 60.3636 29.8303 60.2747 30.4424L60.9186 31.5191C60.7988 31.6617 60.6438 31.784 60.4537 31.886C59.5223 30.5846 58.9418 29.7801 58.7121 29.4724C58.24 28.8398 57.8433 28.3439 57.522 27.9848C57.629 27.825 57.7797 27.7059 57.974 27.6274L58.9383 28.5449C59.0433 28.3467 59.2698 27.7051 59.6179 26.62L60.972 22.2552L60.4265 21.5243L56.0413 24.7973L56.6883 25.9317C56.5856 26.0615 56.4275 26.1796 56.2139 26.2858C55.8376 25.7281 55.4134 25.133 54.9412 24.5004C54.5967 24.0388 54.1479 23.4554 53.5949 22.7501C53.7105 22.5839 53.8643 22.4691 54.0565 22.4055L55.0047 23.3549C55.355 23.1467 55.8679 22.7905 56.5432 22.2865L64.0954 16.6496L63.4485 15.5153C63.5512 15.3854 63.7093 15.2674 63.9229 15.1612C64.2396 15.6569 64.6596 16.2552 65.1827 16.9561C65.6485 17.5801 66.1016 18.1604 66.5419 18.6969C66.4263 18.863 66.2725 18.9778 66.0803 19.0414L65.1321 18.092C64.7818 18.3003 64.2689 18.6564 63.5936 19.1604L61.0805 21.0362L61.5877 21.7158L68.048 21.9452L67.401 20.8109C67.5037 20.681 67.6619 20.5629 67.8754 20.4568C68.1922 20.9524 68.5419 21.4567 68.9247 21.9696C69.2565 22.4141 69.6394 22.9003 70.0734 23.4283C69.9578 23.5944 69.8039 23.7092 69.6118 23.7728L68.8167 23.0286C68.2155 22.865 67.5448 22.76 66.8046 22.7135L62.4915 22.4987Z" fill="black" />
    </svg>
);

const SingleStar = () => (
    <svg
        width="33"
        height="44"
        viewBox="0 0 33 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -top-4 -left-4 w-8 h-10 lg:w-10 lg:h-12"
    >
        <g filter="url(#filter0_d_633_99)">
            <path d="M4.00005 18.128C3.99722 18.6457 4.10858 19.1519 4.31866 19.5761C4.52874 20.0003 4.82711 20.3214 5.17228 20.4949L12.2548 24.1351L14.8292 34.144C14.9561 34.6271 15.1846 35.0438 15.4839 35.3381C15.7833 35.6324 16.1392 35.7901 16.5038 35.7901C16.8684 35.7901 17.2243 35.6324 17.5237 35.3381C17.8231 35.0438 18.0515 34.6271 18.1784 34.144L20.7529 24.1336L27.8353 20.4949C28.1772 20.3155 28.472 19.9926 28.6802 19.5695C28.8884 19.1464 29 18.6433 29 18.128C29 17.6126 28.8884 17.1095 28.6802 16.6864C28.472 16.2633 28.1772 15.9404 27.8353 15.7611L20.7529 12.1224L18.1784 2.11196C18.0515 1.62879 17.8231 1.21208 17.5237 0.917814C17.2243 0.623548 16.8684 0.46582 16.5038 0.46582C16.1392 0.46582 15.7833 0.623548 15.4839 0.917814C15.1846 1.21208 14.9561 1.62879 14.8292 2.11196L12.2536 12.1224L5.17228 15.7611C4.82711 15.9345 4.52874 16.2557 4.31866 16.6798C4.10858 17.104 3.99722 17.6102 4.00005 18.128Z" fill="#750E21" />
        </g>
    </svg>
);

export { BgHeroSection, BgHeroSection1, PatternStockText, SingleStar };
