import React from "react";
// import LogoImg from "../../Assets/Images/PatternStockLogo.png";
import { NavLink } from "react-router-dom";

import PatternStockLogo from "../../Assets/Svg/PatternStockLogo";

const Logo = ({ alt = "Logo", width = 'w-[110px]', height = 'h-[83px]', TranslateX = 0 }) => (
    <NavLink to='/' className={`flex items-center pr-2 p-[14px] md:p-[20px] text-gray-700 hover:text-gray-900 ${width} ${height} ${TranslateX}`}>
        {/* <img src={LogoImg} alt={alt} style={{ width, height }} loading="lazy" /> */}
        <PatternStockLogo width={width} height={height} />
    </NavLink>
);

export default Logo;
