import React from "react";

const PatternStockLogo = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 3429.92 4251.97"
        style={{ enableBackground: "new 0 0 3429.92 4251.97" }}
        xmlSpace="preserve"
    >
        <style type="text/css">{`
      .st0 { fill: #D62727; }
      .st1 { fill: #010101; }
    `}</style>
        <g>
            <path className="st0" d="M344.43,1713.13H101.62c-52.54,0-98.22,39.68-101.75,92.1c-2.02,30.1,9.39,57.51,28.74,76.86
		c17.87,17.87,42.61,28.97,69.99,28.97h218.02c55.51,0,76.76,28.86,76.76,72.21s-21.25,72.32-76.76,72.32H96.97
		c-53.75,0-97.32,43.57-97.32,97.32l0,0c0,53.75,43.57,97.33,97.32,97.33h247.46c197.93,0,299.11-121.27,299.11-266.97
		C643.55,1824.25,542.36,1713.13,344.43,1713.13z"/>
            <path className="st1" d="M246.63,2411.21v3.17c0,68.2-55.29,123.49-123.49,123.49l0,0c-68.2,0-123.49-55.29-123.49-123.49v-3.17
		c0-68.2,55.29-123.49,123.49-123.49l0,0C191.34,2287.71,246.63,2343,246.63,2411.21z"/>
            <path className="st1" d="M600.18,2498.14c-17.03,13.25-33.5,23.18-49.41,29.8s-33.76,9.93-53.54,9.93c-18.06,0-33.93-3.57-47.6-10.71
		s-24.21-16.81-31.6-29.03c-7.4-12.21-11.09-25.45-11.09-39.73c0-19.26,6.1-35.69,18.32-49.28c12.21-13.59,28.98-22.71,50.31-27.35
		c4.47-1.03,15.56-3.35,33.28-6.96c17.71-3.61,32.9-6.92,45.54-9.93s26.36-6.66,41.15-10.97c-0.86-18.58-4.6-32.21-11.22-40.89
		s-20.34-13.03-41.15-13.03c-17.89,0-31.35,2.5-40.38,7.48c-9.03,4.99-16.77,12.47-23.22,22.44c-6.45,9.98-11.01,16.56-13.67,19.74
		c-2.67,3.18-8.39,4.77-17.16,4.77c-7.91,0-14.75-2.54-20.51-7.61s-8.64-11.56-8.64-19.48c0-12.39,4.39-24.42,13.16-36.12
		c8.77-11.7,22.45-21.33,41.02-28.9c18.58-7.57,41.71-11.35,69.4-11.35c30.96,0,55.3,3.66,73.01,10.97s30.23,18.88,37.54,34.7
		c7.31,15.83,10.97,36.81,10.97,62.95c0,16.51-0.04,30.53-0.13,42.05c-0.09,11.53-0.22,24.34-0.39,38.44
		c0,13.25,2.19,27.05,6.58,41.41c4.39,14.36,6.58,23.61,6.58,27.73c0,7.23-3.4,13.8-10.19,19.74c-6.8,5.93-14.49,8.9-23.09,8.9
		c-7.22,0-14.36-3.4-21.41-10.19C615.57,2520.89,608.09,2511.04,600.18,2498.14z M595.54,2396.23c-10.32,3.79-25.33,7.79-45.02,12
		c-19.7,4.22-33.33,7.31-40.89,9.29c-7.57,1.98-14.79,5.85-21.67,11.61s-10.32,13.8-10.32,24.12c0,10.67,4.04,19.74,12.13,27.22
		c8.08,7.48,18.66,11.22,31.73,11.22c13.93,0,26.79-3.05,38.57-9.16c11.78-6.1,20.42-13.97,25.93-23.61
		c6.36-10.66,9.55-28.2,9.55-52.63L595.54,2396.23L595.54,2396.23z"/>
            <path className="st1" d="M726.86,2257.69h7.74v-42.31c0-11.35,0.3-20.25,0.9-26.7c0.6-6.45,2.28-12,5.03-16.64
		c2.75-4.81,6.71-8.73,11.87-11.74s10.92-4.51,17.29-4.51c8.94,0,17.03,3.35,24.25,10.06c4.81,4.47,7.87,9.93,9.16,16.38
		s1.93,15.61,1.93,27.48v47.99h25.8c9.97,0,17.58,2.37,22.83,7.09c5.25,4.73,7.87,10.8,7.87,18.19c0,9.46-3.74,16.08-11.22,19.87
		c-7.48,3.78-18.19,5.68-32.12,5.68h-13.16v129.52c0,11.01,0.39,19.48,1.16,25.41c0.77,5.93,2.84,10.75,6.19,14.45
		s8.81,5.55,16.38,5.55c4.13,0,9.72-0.73,16.77-2.19c7.05-1.46,12.55-2.19,16.51-2.19c5.68,0,10.79,2.28,15.35,6.84
		s6.84,10.19,6.84,16.9c0,11.35-6.19,20.04-18.58,26.06c-12.38,6.02-30.19,9.03-53.41,9.03c-22.02,0-38.7-3.7-50.05-11.1
		c-11.35-7.39-18.79-17.63-22.32-30.7s-5.29-30.53-5.29-52.37v-135.19h-9.29c-10.15,0-17.89-2.41-23.22-7.23
		c-5.33-4.81-8-10.92-8-18.32c0-7.39,2.79-13.45,8.39-18.19C708.07,2260.05,716.19,2257.69,726.86,2257.69z"/>
            <path className="st1" d="M913.91,2257.69h7.74v-42.31c0-11.35,0.3-20.25,0.9-26.7c0.6-6.45,2.28-12,5.03-16.64
		c2.75-4.81,6.71-8.73,11.87-11.74s10.92-4.51,17.29-4.51c8.94,0,17.03,3.35,24.25,10.06c4.81,4.47,7.87,9.93,9.16,16.38
		s1.93,15.61,1.93,27.48v47.99h25.8c9.97,0,17.58,2.37,22.83,7.09c5.25,4.73,7.87,10.8,7.87,18.19c0,9.46-3.74,16.08-11.22,19.87
		c-7.48,3.78-18.19,5.68-32.12,5.68h-13.16v129.52c0,11.01,0.39,19.48,1.16,25.41c0.77,5.93,2.84,10.75,6.19,14.45
		s8.81,5.55,16.38,5.55c4.13,0,9.72-0.73,16.77-2.19c7.05-1.46,12.55-2.19,16.51-2.19c5.68,0,10.79,2.28,15.35,6.84
		c4.56,4.56,6.84,10.19,6.84,16.9c0,11.35-6.19,20.04-18.58,26.06c-12.38,6.02-30.19,9.03-53.41,9.03c-22.02,0-38.7-3.7-50.05-11.1
		c-11.35-7.39-18.79-17.63-22.32-30.7s-5.29-30.53-5.29-52.37v-135.19h-9.29c-10.15,0-17.89-2.41-23.22-7.23
		c-5.33-4.81-8-10.92-8-18.32c0-7.39,2.79-13.45,8.39-18.19C895.12,2260.05,903.24,2257.69,913.91,2257.69z"/>
            <path className="st1" d="M1293.69,2411.97h-137.77c0.17,16,3.39,30.1,9.68,42.31c6.28,12.21,14.62,21.41,25.03,27.6
		c10.4,6.19,21.89,9.29,34.44,9.29c8.43,0,16.12-0.99,23.09-2.97s13.71-5.07,20.25-9.29c6.54-4.21,12.55-8.73,18.06-13.55
		c5.5-4.81,12.64-11.35,21.41-19.61c3.61-3.1,8.77-4.64,15.48-4.64c7.22,0,13.07,1.98,17.54,5.93c4.47,3.96,6.71,9.55,6.71,16.77
		c0,6.37-2.5,13.8-7.48,22.32c-4.99,8.52-12.51,16.69-22.58,24.51c-10.06,7.83-22.7,14.32-37.93,19.48
		c-15.22,5.16-32.73,7.74-52.5,7.74c-45.24,0-80.41-12.9-105.52-38.7s-37.67-60.8-37.67-105.01c0-20.81,3.1-40.12,9.29-57.92
		c6.19-17.8,15.22-33.07,27.09-45.8s26.49-22.49,43.86-29.28s36.64-10.19,57.79-10.19c27.52,0,51.12,5.8,70.82,17.42
		c19.69,11.61,34.44,26.62,44.25,45.02c9.8,18.41,14.71,37.15,14.71,56.25c0,17.72-5.07,29.2-15.22,34.44
		C1326.36,2409.35,1312.09,2411.97,1293.69,2411.97z M1155.91,2371.98h127.71c-1.72-24.08-8.22-42.09-19.48-54.05
		c-11.27-11.95-26.1-17.93-44.51-17.93c-17.54,0-31.95,6.06-43.21,18.19C1165.16,2330.31,1158.32,2348.24,1155.91,2371.98z"/>
            <path className="st1" d="M1480.99,2436.22v58.82c0,14.28-3.35,24.99-10.06,32.12c-6.71,7.13-15.22,10.71-25.54,10.71
		c-10.15,0-18.49-3.61-25.03-10.84c-6.54-7.22-9.8-17.89-9.8-31.99v-196.08c0-31.64,11.44-47.47,34.31-47.47
		c11.69,0,20.12,3.7,25.28,11.1c5.16,7.4,8,18.32,8.52,32.77c8.42-14.45,17.07-25.37,25.93-32.77c8.86-7.39,20.68-11.1,35.48-11.1
		c14.79,0,29.15,3.7,43.08,11.1c13.93,7.4,20.9,17.2,20.9,29.41c0,8.6-2.97,15.7-8.9,21.28c-5.94,5.59-12.34,8.39-19.22,8.39
		c-2.58,0-8.82-1.59-18.71-4.77s-18.62-4.77-26.19-4.77c-10.32,0-18.75,2.71-25.28,8.13c-6.54,5.42-11.61,13.46-15.22,24.12
		c-3.61,10.67-6.11,23.35-7.48,38.05C1481.68,2397.14,1480.99,2415.06,1480.99,2436.22z"/>
            <path className="st1" d="M2833.16,2394.68c0,20.99-3.27,40.34-9.8,58.05c-6.54,17.72-16,32.94-28.38,45.67
		c-12.39,12.73-27.18,22.49-44.38,29.28s-36.55,10.19-58.05,10.19c-21.33,0-40.5-3.44-57.53-10.32
		c-17.03-6.88-31.78-16.73-44.25-29.54c-12.48-12.81-21.93-27.95-28.38-45.41s-9.68-36.76-9.68-57.92c0-21.32,3.26-40.85,9.8-58.56
		s15.91-32.85,28.12-45.41c12.21-12.55,27-22.23,44.38-29.03c17.37-6.79,36.55-10.19,57.53-10.19c21.33,0,40.68,3.44,58.05,10.32
		c17.37,6.88,32.25,16.68,44.63,29.41c12.39,12.73,21.8,27.86,28.25,45.41C2829.94,2354.18,2833.16,2373.53,2833.16,2394.68z
		 M2762.48,2394.68c0-28.72-6.32-51.08-18.97-67.08s-29.63-24-50.96-24c-13.76,0-25.89,3.57-36.38,10.71s-18.57,17.67-24.25,31.6
		c-5.68,13.93-8.52,30.19-8.52,48.76c0,18.41,2.79,34.49,8.39,48.25c5.59,13.77,13.58,24.3,24,31.61
		c10.41,7.31,22.66,10.97,36.76,10.97c21.33,0,38.32-8.04,50.96-24.12C2756.15,2445.29,2762.48,2423.06,2762.48,2394.68z"/>
            <path className="st1" d="M3141.22,2450.67c0,8.77-2.62,18.15-7.87,28.12c-5.25,9.98-13.25,19.48-24,28.51s-24.3,16.3-40.64,21.8
		c-16.34,5.5-34.74,8.26-55.21,8.26c-43.52,0-77.49-12.68-101.91-38.06c-24.42-25.37-36.63-59.38-36.63-102.04
		c0-28.9,5.58-54.44,16.77-76.63c11.18-22.19,27.35-39.34,48.5-51.47s46.44-18.19,75.85-18.19c18.23,0,34.96,2.67,50.18,8
		s28.12,12.21,38.7,20.64c10.58,8.43,18.66,17.41,24.25,26.96s8.38,18.45,8.38,26.7c0,8.43-3.14,15.57-9.41,21.42
		c-6.28,5.85-13.89,8.77-22.83,8.77c-5.85,0-10.71-1.5-14.58-4.51s-8.22-7.87-13.03-14.58c-8.6-13.07-17.59-22.87-26.96-29.41
		c-9.37-6.53-21.28-9.8-35.73-9.8c-20.82,0-37.59,8.13-50.31,24.38c-12.73,16.25-19.1,38.49-19.1,66.69
		c0,13.25,1.64,25.41,4.91,36.51c3.26,11.09,8,20.55,14.19,28.38c6.19,7.83,13.67,13.76,22.45,17.8c8.77,4.04,18.4,6.06,28.9,6.06
		c14.1,0,26.18-3.27,36.25-9.81c10.07-6.53,18.96-16.51,26.71-29.93c4.29-7.91,8.94-14.1,13.93-18.58
		c4.99-4.47,11.09-6.71,18.32-6.71c8.6,0,15.74,3.27,21.41,9.8C3138.38,2436.31,3141.22,2443.28,3141.22,2450.67z"/>
            <path className="st1" d="M3357.94,2505.62l-62.18-102.17l-38.19,36.12v55.98c0,13.59-3.57,24.04-10.71,31.35s-15.35,10.97-24.64,10.97
		c-10.84,0-19.35-3.61-25.54-10.84c-6.19-7.22-9.29-17.89-9.29-31.99v-300.31c0-15.65,3.01-27.56,9.03-35.74
		c6.02-8.17,14.62-12.25,25.8-12.25c10.84,0,19.43,3.7,25.8,11.09c6.36,7.4,9.54,18.32,9.54,32.77v170.79l79.21-83.07
		c9.81-10.32,17.29-17.37,22.45-21.16c5.16-3.78,11.44-5.68,18.83-5.68c8.77,0,16.08,2.8,21.93,8.39c5.84,5.59,8.77,12.6,8.77,21.03
		c0,10.32-9.55,24.08-28.64,41.28l-37.41,34.31l72.24,113.52c5.33,8.43,9.16,14.83,11.48,19.22s3.48,8.56,3.48,12.51
		c0,11.18-3.05,19.99-9.16,26.45c-6.11,6.45-14.15,9.68-24.12,9.68c-8.61,0-15.23-2.32-19.87-6.97
		C3372.13,2526.26,3365.85,2517.84,3357.94,2505.62z"/>
            <path className="st0" d="M2340.29,2461.25c0,8.1-4.55,15.27-13.59,21.56c-8.64,5.96-19.62,9.11-32.94,9.31
		c-0.6,0.07-1.14,0.07-1.74,0.07c-17.41,0-31.07-3.08-41.18-9.24c-10.04-6.23-18.68-15.6-25.91-28.12
		c-3.62-6.56-7.37-11.65-11.32-15.27s-9.64-5.42-17.07-5.42c-9.96,0-17.87,3.82-23.72,11.59c-3.82,5.07-5.54,11.27-5.4,17.61
		c0.25,11.36,4.54,22.65,12.91,33.88c3.01,4.09,10.35,9.47,18.92,14.8c9.54,5.94,5.33,20.69-5.92,20.69h-98.86
		c-11.26,0-15.42-14.66-5.92-20.71c8.79-5.6,16.45-11.44,19.79-16.32c9.44-13.79,14.2-30.47,14.2-49.88
		c0-12.92-3.62-24.37-10.71-34.48c-7.16-10.04-17.68-18.75-31.47-26.18c-13.86-7.37-31.74-13.73-53.57-19.08
		c-18.41-4.49-32.88-8.24-43.32-11.32c-10.51-3.15-18.88-7.03-25.04-11.78c-6.23-4.69-9.31-10.78-9.31-18.15
		c0-8.1,4.22-14.86,12.65-20.29c8.44-5.42,19.02-8.1,31.74-8.1c12.39,0,22.16,2.08,29.39,6.16c7.23,4.15,13.53,9.64,18.82,16.54
		c6.56,8.24,12.39,14.46,17.48,18.55c5.02,4.15,11.18,6.23,18.41,6.23c9.98,0,17.68-2.61,23.1-7.77c5.42-5.16,8.1-11.78,8.1-19.89
		c0-7.37-2.54-14.93-7.57-22.7c-5.09-7.7-12.52-14.73-22.23-21.16c-0.1-0.06-0.2-0.13-0.3-0.19c-7.12-4.54-4.16-15.48,4.29-15.48
		h121.35c8.37,0,11.51,10.92,4.43,15.39c-10.96,6.92-21.71,14.83-25.65,20.7c-8.44,12.52-12.66,25.85-12.66,39.97
		c0,15.8,4.55,29.13,13.66,39.97c9.17,10.85,21.29,19.62,36.56,26.18c15.2,6.63,34.95,12.99,59.19,19.02
		c21.49,5.16,36.89,10.58,46.34,16.2c8.37,5.09,12.99,12.86,13.86,23.3C2340.23,2458.64,2340.29,2459.91,2340.29,2461.25z"/>
            <g>
                <path className="st1" d="M2536.85,2485.92c-4.55-4.56-9.67-6.84-15.35-6.84c-3.96,0-9.46,0.73-16.51,2.19s-12.64,2.19-16.77,2.19
			c-7.56,0-13.02-1.85-16.38-5.55c-3.35-3.7-5.41-8.52-6.19-14.45c-0.77-5.93-1.16-14.4-1.16-25.41v-129.52h13.16
			c13.93,0,24.64-1.9,32.12-5.68c7.48-3.79,11.22-10.41,11.22-19.87c0-7.39-2.63-13.46-7.87-18.19c-5.25-4.72-12.86-7.09-22.83-7.09
			h-25.8v-47.99c0-11.87-0.65-21.03-1.94-27.48c-1.29-6.45-4.34-11.91-9.16-16.38c-7.22-6.71-15.31-10.06-24.25-10.06
			c-6.37,0-12.13,1.5-17.29,4.51c-5.16,3.01-9.11,6.93-11.86,11.74c-2.76,4.64-4.44,10.19-5.04,16.64c-0.6,6.45-0.9,15.35-0.9,26.7
			v42.31h-0.01v50.86v16.33c-1.28,4.32-3.6,8.04-6.93,11.23c-5.42,5.16-13.12,7.77-23.1,7.77c-7.23,0-13.39-2.08-18.41-6.23
			c-5.09-4.09-10.92-10.31-17.48-18.55c-5.29-6.9-11.59-12.39-18.82-16.54c-7.24-4.08-17.01-6.16-29.4-6.16
			c-12.72,0-23.3,2.68-31.74,8.1c-8.44,5.43-12.66,12.19-12.66,20.29c0,7.37,3.08,13.46,9.31,18.15
			c6.16,4.75,14.53,8.63,25.04,11.78c10.44,3.08,24.91,6.83,43.32,11.32c21.83,5.35,39.71,11.71,53.5,19.08
			c11.38,6.1,20.5,13.07,27.37,20.92v37.7c0,21.84,1.76,39.3,5.29,52.37s10.97,23.31,22.32,30.7c11.35,7.4,28.04,11.1,50.05,11.1
			c23.23,0,41.03-3.01,53.41-9.03c12.39-6.02,18.58-14.71,18.58-26.06C2543.69,2496.11,2541.41,2490.48,2536.85,2485.92z"/>
                <path className="st1" d="M2039.86,2434.13c-9.38-5.62-24.85-11.04-46.34-16.2c-24.24-6.03-43.99-12.39-59.19-19.02
			c-15.26-6.56-27.38-15.33-36.49-26.18c-2.47-2.93-4.58-6.03-6.36-9.35c-0.02-15.21-0.71-27.73-2.06-37.58
			c-1.38-9.98-4.39-19.27-9.03-27.87c-7.23-14.79-18.15-26.22-32.77-34.31c-14.62-8.09-31.21-12.13-49.79-12.13
			c-19.1,0-35.99,3.83-50.7,11.48c-14.71,7.66-28.26,19.65-40.64,35.99v-8.51c0-8.42-1.51-15.56-4.5-21.42
			c-3.02-5.85-7.01-10.23-12-13.16c-4.99-2.92-10.58-4.39-16.77-4.39c-10.15,0-18.15,3.31-24,9.93c-5.85,6.63-8.77,16.64-8.77,30.06
			v203.56c0,13.76,3.26,24.34,9.8,31.73c6.54,7.4,14.88,11.1,25.03,11.1c10.49,0,19.05-3.58,25.67-10.71
			c6.61-7.13,9.93-17.84,9.93-32.12v-88.23c0-28.73,1.72-48.34,5.16-58.83c4.82-13.07,12.39-23.35,22.71-30.83
			c10.31-7.48,21.75-11.22,34.31-11.22c19.26,0,32.12,6.16,38.57,18.45c6.45,12.3,9.68,30.06,9.68,53.28v117.39
			c0,13.76,3.26,24.34,9.8,31.73c6.53,7.4,15.05,11.1,25.54,11.1c10.32,0,18.7-3.61,25.15-10.84c6.46-7.22,9.68-17.88,9.68-31.99
			v-54.52c5.14-4.26,11.39-6.39,18.68-6.39c7.43,0,13.12,1.8,17.07,5.42s7.7,8.71,11.32,15.27c7.23,12.52,15.87,21.89,25.91,28.12
			c10.11,6.16,23.84,9.24,41.18,9.24c14.12,0,25.64-3.14,34.68-9.37c9.04-6.29,13.59-13.46,13.59-21.56
			C2053.91,2448.87,2049.22,2439.83,2039.86,2434.13z"/>
            </g>
            <path className="st1" d="M2396.27,2256.69v50.82h-2.23c-1.09-4.85-3.32-9.78-6.57-14.81c-5.07-7.59-12.29-14.52-21.85-20.79
		c-1.82-1.2-3.76-2.37-5.76-3.43c1.17-1.68,2.59-3.25,4.3-4.67c5.58-4.74,13.72-7.11,24.37-7.11h7.74V2256.69z"/>
        </g>
    </svg>
);

export default PatternStockLogo;
