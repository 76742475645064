import React from "react";
import { BgHeroSection, PatternStockText } from "../../../Assets/Svg/PrinterSvg";
import HeroSection1 from "../../../Assets/Images/Printer/HeroSection2.webp";
import HeroSection2 from "../../../Assets/Images/Printer/HeroSection1.webp";

const HeroSection = () => {
      
    return (
        <section className="relative bg-white pb-20 min-h-screen overflow-hidden">
            {/* Background SVG */}
            <div className="absolute bottom-0 left-0 w-full z-10">
                <BgHeroSection />
            </div>

            <div className="container mx-auto p-4 md:p-12 flex flex-col lg:flex-row items-center lg:items-start relative z-10">
                {/* Left Section (Text Content) */}
                <div className="w-full lg:w-1/2 text-center space-y-12 md:space-y-6 mt-12">
                    <h1 className="text-[28px] md:text-[48px] lg:text-[35px] xl:text-[50px] 2xl:text-[60px] leading-tight bg-gradient-to-r from-black via-[#B1354C] via-[#D72323] via-[#871F32] to-[#750E21] bg-clip-text text-transparent">
                        Step <span className="text-red-600">into the</span> printer world
                        <div className="inline-flex mt-2">
                            <PatternStockText />
                            <span className="bg-gradient-to-r from-black via-[#B1354C] via-[#D72323] via-[#871F32] to-[#750E21] bg-clip-text text-transparent mt-3">
                                <span className="text-black">in</span> Digital Printing!
                            </span>
                        </div>
                    </h1>

                    <div className="mt-10 text-center">
                        <h2 className="md:text-2xl xl:text-2xl 2xl:text-[2rem] font-semibold text-gray-900">Who We Are ?</h2>
                        <div className="w-12 md:w-16 2xl:w-20 h-1 bg-[#750E21] rounded-xl my-4 mb-4 mx-auto"></div>
                        <p className="text-gray-700 text-[16px] md:text-lg lg:text-[1rem] xl:text-lg 3xl:text-[1.525rem] leading-relaxed">
                            Our company, <span className="font-semibold text-gray-900">Patterns Stock</span>, was established in 2021 with the goal of providing innovative and high-quality solutions in the fabric and textile printing industry.
                            <br />Over the years, we have become leaders in offering customized design and printing services that cater to both local and international markets.
                        </p>
                    </div>
                </div>

                {/* Right Section (Image) */}
                <div className="md:w-1/2 lg:flex justify-end xl:justify-end 2xl:justify-center mt-8 md:mt-0 hidden">
                    <img src={HeroSection1} alt="HeroSection" className="lg:w-[9rem] xl:w-[11rem] 2xl:w-[13rem] lg:h-[12rem] xl:h-[15rem] 2xl:h-[17rem] mt-auto" loading="lazy" />
                    <img src={HeroSection2} alt="HeroSection" className="lg:w-[18rem] xl:w-[21rem] 2xl:w-[29rem] lg:h-[28rem] xl:h-[34rem] 2xl:h-[40rem] mt-auto" loading="lazy" />
                </div>
            </div>

            {/* Bottom Bar with "Pattern Stock" Text */}
            <div className="absolute bottom-0 left-0 w-full bg-[#750E21] text-white py-3 text-lg font-bold uppercase tracking-wide overflow-hidden z-30">
                <div className="container mx-auto flex justify-between items-center">
                    {/* Left Section: 4 times */}
                    <div className="hidden lg:flex gap-x-0">
                        <span className="lg:text-[10px] xl:text-[15px] font-normal lg:-translate-x-16 xl:-translate-x-32">Pattern Stock</span>
                    </div>

                    {/* Center Section: 2 times */}
                    <div className="flex gap-x-[2.6rem] 2sm:gap-x-[4rem] md:gap-x-56 xl:gap-x-72">
                        <span className="text-[10px] lg:text-[10px] xl:text-[15px] font-normal">Pattern Stock</span>
                        <span className="text-[10px] lg:text-[10px] xl:text-[15px] font-normal">Pattern Stock</span>
                        <span className="text-[10px] lg:text-[10px] xl:text-[15px] font-normal">Pattern Stock</span>
                    </div>

                    {/* Right Section: 1 time */}
                    <div className="hidden lg:flex gap-x-28">
                        <span className="lg:text-[10px] xl:text-[15px] font-normal lg:translate-x-16 xl:translate-x-28">Pattern Stock</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
