import React, { useState } from "react";
import { PriceBg } from "../Assets/Svg/PriceBg";

const PricingSection = () => {
    const [billingCycle, setBillingCycle] = useState("monthly");

    const plansA = [
        {
            name: "BASIC",
            description: "This package is ideal for customers who need simple, ready-made designs without complex customization.",
            price: 89,
            features: [
                "Standard resolution suitable for everyday use.",
                "No customization options available.",
                "Limited email support.",
                "Limited number of designs (50 Design).",
            ],
            startColor: "#093028",
            endColor: "#237A57",
        },
        {
            name: "STANDARD",
            description: "This package is suited for businesses or individuals who need a variety of designs with minor customization options.",
            price: 119,
            features: [
                "High resolution suitable for printing and versatile use.",
                "More designs included (350 Design).",
                "Faster email support.",
            ],
            startColor: "#3A6186",
            endColor: "#89253E",
        },
        {
            name: "PREMIUM",
            description: "This package is perfect for customers who require fully customized designs or have complex needs.",
            price: 159,
            features: [
                "Full customization available.",
                "High-quality resolution for professional printing and versatile uses.",
                "Large number of designs (750 Design).",
                "Instant live chat support.",
            ],
            startColor: "#554023",
            endColor: "#C99846",
        },
    ];

    const plansB = [
        {
            name: "BASIC",
            description: "This package is ideal for customers who need simple, ready-made designs without complex customization.",
            price: 868,
            features: [
                "Standard resolution suitable for everyday use.",
                "No customization options available.",
                "Limited email support.",
                "Limited number of designs (50 Design).",
            ],
            startColor: "#554023",
            endColor: "#C99846",
        },
        {
            name: "STANDARD",
            description: "This package is suited for businesses or individuals who need a variety of designs with minor customization options.",
            price: 1228,
            features: [
                "High resolution suitable for printing and versatile use.",
                "More designs included (350 Design).",
                "Faster email support.",
            ],
            startColor: "#3A6186",
            endColor: "#89253E",
        },
        {
            name: "PREMIUM",
            description: "This package is perfect for customers who require fully customized designs or have complex needs.",
            price: 1708,
            features: [
                "Full customization available.",
                "High-quality resolution for professional printing and versatile uses.",
                "Large number of designs (750 Design).",
                "Instant live chat support.",
            ],
            startColor: "#093028",
            endColor: "#237A57",
        },
    ];

    const selectedPlans = billingCycle === "monthly" ? plansA : plansB;

    return (
        <div className="py-16 px-6 min-h-screen bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]/60 text-center">
            {/* Header */}
            <h2 className="text-[#D72323] font-semibold text-sm tracking-wide">PRICING</h2>
            <h3 className="text-4xl mt-2">Choose your right plan!</h3>
            <p className="mt-2 text-gray-600 text-lg">
                Select the best plan ensuring a perfect match. Need more or less? Customize your subscription for a seamless fit.
            </p>

            {/* Toggle Buttons */}
            <div className="flex justify-center mt-6">
                <button
                    className={`px-6 py-2 border border-[#750E21] rounded-l-lg transition ${billingCycle === "monthly" ? "bg-[#750E21] text-white" : "bg-transparent text-gray-700"
                        }`}
                    onClick={() => setBillingCycle("monthly")}
                >
                    Monthly
                </button>
                <button
                    className={`px-6 py-2 border border-[#750E21] rounded-r-lg transition ${billingCycle === "annual" ? "bg-[#750E21] text-white" : "bg-transparent text-gray-700"
                        }`}
                    onClick={() => setBillingCycle("annual")}
                >
                    Annual
                </button>
            </div>

            {/* Pricing Cards */}
            <div className="flex flex-wrap justify-center gap-16 mt-12 z-50">
                {selectedPlans.map((plan, index) => (
                    <div key={index} className="relative bg-white shadow-lg rounded-br-[100px] rounded-tl-[65px] overflow-visible w-[355px] text-black flex flex-col z-50">
                        {/* Background Gradient */}
                        <div
                            className="absolute right-0 bottom-0 translate-y-[0.5rem] rounded-br-[100px] px-4 py-2 shadow-md w-[95%] h-[100px] -z-10"
                            style={{ background: `linear-gradient(to bottom, ${plan.startColor}, ${plan.endColor})`, zIndex: 0 }}
                        ></div>

                        {/* Top Section: SVG Background, Plan Name, Description */}
                        <div className="relative p-6 text-white h-[250px]">
                            <div className="absolute inset-0">
                                <PriceBg startColor={plan.startColor} endColor={plan.endColor} />
                            </div>

                            <h3 className="text-[30px] relative z-10">{plan.name}</h3>
                            <p className="mt-6 text-[13px] relative z-10">{plan.description}</p>
                        </div>

                        {/* Bottom Section: Features & Button */}
                        <div className="p-4 flex-grow relative z-50">
                            <ul className="space-y-[23px] text-[13px]">
                                {plan.features.map((feature, i) => (
                                    <li key={i} className="flex items-start space-x-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 flex-shrink-0" viewBox="0 0 15 11" fill="none">
                                            <path d="M14.7447 1.47799L5.51669 10.706C5.43632 10.7867 5.34082 10.8507 5.23566 10.8943C5.1305 10.938 5.01776 10.9605 4.90389 10.9605C4.79003 10.9605 4.67729 10.938 4.57213 10.8943C4.46697 10.8507 4.37147 10.7867 4.29109 10.706L0.25383 6.66876C0.173356 6.58828 0.10952 6.49275 0.0659682 6.3876C0.022416 6.28246 1.19916e-09 6.16976 0 6.05596C-1.19915e-09 5.94215 0.022416 5.82946 0.0659682 5.72431C0.10952 5.61917 0.173356 5.52363 0.25383 5.44316C0.334304 5.36268 0.42984 5.29885 0.534984 5.2553C0.640128 5.21174 0.752822 5.18933 0.866629 5.18933C0.980436 5.18933 1.09313 5.21174 1.19827 5.2553C1.30342 5.29885 1.39895 5.36268 1.47943 5.44316L4.90461 8.86835L13.5206 0.25383C13.6831 0.0913051 13.9035 0 14.1334 0C14.3632 0 14.5836 0.0913051 14.7462 0.25383C14.9087 0.416354 15 0.636785 15 0.866629C15 1.09647 14.9087 1.3169 14.7462 1.47943L14.7447 1.47799Z" fill="black" />
                                        </svg>
                                        <span className="text-start">{feature}</span>
                                    </li>
                                ))}
                            </ul>
                            <div
                                className="absolute -left-[109px] top-1/2 w-1/2 px-4 py-2 text-white rounded-t-2xl -rotate-90 -z-10"
                                style={{ background: `linear-gradient(to bottom, ${plan.startColor}, ${plan.endColor})` }}
                            >
                                ${plan.price}
                            </div>
                        </div>

                        {/* Button */}
                        <div className="px-4 py-8 mt-auto bg-white rounded-br-[100px] mx-auto w-full relative z-50">
                            <button
                                className="border text-black text-[17px] w-[50%] py-1 px-8 rounded-md"
                                style={{ borderColor: plan.endColor }}
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PricingSection;
